import type { IInputChange } from 'types';

import {
	KeyboardEvent,
	createRef,
	useCallback,
	useMemo,
} from 'react';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { IconBtn, Input } from '@storybook';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useOutsideClick } from 'hooks';
import { useDrop } from 'react-dnd';
import { IsConfigDrawerOpen, IsShowMessage } from 'views/config-items';
import { templateTypeState } from 'views/prepare-dashboard';
import { EnvelopePurposeState, webComponentEnvelopeIdState } from 'states';
import { IsCommonField, SelectedRecipientState } from 'views/reciepient-modal';

import {
	ActiveMenuTabState,
	IMenuTab,
	menuBtns,
	SearchedMenuNameState,
	ConfigTabMenuState,
	DragTypes,
	DropItemsState,
	TabConfigurationState,
	isSearchFocused,
	CommonFields,
} from '../../store';

import { SearchedItems, DeleteNodesZone, SelectRecipient } from './components';
import { MenuItems } from './menu-items';

type IMenuObj = {
	[key in IMenuTab]: JSX.Element;
};

export const LeftSidebar = () => {
	const [searchedText, setSearchedText] = useRecoilState(SearchedMenuNameState);
	const [activeMenuTab, setActiveMenuTab] = useRecoilState(ActiveMenuTabState);
	const setDroptItems = useSetRecoilState(DropItemsState);
	const { menuTabs } = useRecoilValue(ConfigTabMenuState);
	const tabConfiguration = useRecoilValue(TabConfigurationState);
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const setShowMessage = useSetRecoilState(IsShowMessage);
	const templateType = useRecoilValue(templateTypeState);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const envelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const isCommonField = useRecoilValue(IsCommonField);
	const currentRecipient = useRecoilValue(SelectedRecipientState);

	const [isFocus, setIsFocus] = useRecoilState(isSearchFocused);
	const ref = createRef<HTMLDivElement>();

	const [{ isOver }, dropRef] = useDrop({
		accept: DragTypes.DroppedItem,
		collect: (monitor) => ({
			isOver: monitor.isOver() && monitor.canDrop(),
		}),
		drop: (item: unknown) => {
			if (item && typeof item === 'object' && 'id' in item) {
				setDroptItems((dropItems) =>
					dropItems.filter(({ id }) => id !== item.id)
				);
				setIsDrawerOpen(false);
				setShowMessage(false);
			}
		},
	});

	const handleSearch = useCallback(
		(e: IInputChange) => {
			const { value } = e.target;
			setSearchedText(value);
		},
		[setSearchedText]
	);

	const handleClearField = useCallback(() => {
		setSearchedText('');
	}, [setSearchedText]);

	const menuObj: IMenuObj = {
		custom: <MenuItems menu={menuTabs.custom} showAdd={true} />,
		standard: <MenuItems menu={menuTabs.standard} />,
		compliance: <MenuItems menu={menuTabs.compliance} />,
		questionnaire: <MenuItems menu={menuTabs.questionnaire} />,
		kyb: <MenuItems menu={menuTabs.kyb} />,
		'Common Fields': <MenuItems menu={menuTabs.common} />
	};

	const handleToggle = useCallback(
		(name: IMenuTab) => {
			setActiveMenuTab(name);
			setSearchedText('');
		},
		[setActiveMenuTab, setSearchedText]
	);

	/**
	 * This code uses the !(condition) syntax to check the negation of the conditions,
	 * making it more concise. It excludes "compliance" if kyc is false and excludes "questionnaire"
	 * if questionnaire is false. The rest of the buttons are included in the result.
	 * This solution is both shorter and functionally equivalent to the previous one.
	 * */
	const sideMenuBtns = useMemo(() => {
		const { kyc, questionnaire, kyb } = tabConfiguration;
		return menuBtns.filter(({ name }) => {
			// when common field is active only return common field menu tab
			if (isCommonField) {
				return name === CommonFields;
			}
			if(templateType === 'editable' && !envelopeId){
				return name === CommonFields;
			}
			if(templateType === 'editable' && envelopeId){
				return name === 'custom' || name === 'standard';
			}
			// condition for multisignAgreement kyb kyc tabs
			if (purpose === 'multisignAgreement') {
				const isMultiSignerKybKycTabs =
					currentRecipient[0]?.type === 'onboarding';

				if (isMultiSignerKybKycTabs) {
					return (
						!(name === CommonFields && !isCommonField) &&
						!(name === 'compliance' && !kyc) &&
						!(name === 'questionnaire' && !questionnaire) &&
						!(name === 'kyb' && !kyb)
					);
				}

				return name === 'custom' || name === 'standard';
			}

			return (
				!(name === 'compliance' && !kyc) &&
				!(name === 'questionnaire' && !questionnaire) &&
				!(name === 'kyb' && !kyb) &&
				!(name === CommonFields && !isCommonField)
			);
		});
	}, [tabConfiguration, isCommonField, templateType, envelopeId, purpose, currentRecipient]);	

	const renderMenuBtns = useMemo(
		() =>
			sideMenuBtns.map(({ className, name, label }, index) => (
				<div key={name}>
					<IconBtn
						id={`${name}-${index}`}
						btnClassName={className}
						handleClickIcon={() => handleToggle(name)}
						isDisabled={false}
						className={`menu-tab__btns menu-tab__btns${
							activeMenuTab === name ? '--active' : ''
						}`}
					/>
					<ReactTooltip
						anchorId={`${name}-${index}`}
						place="right"
						content={label}
						style={{ zIndex: 1000, color: '#ffffff', fontSize: 12 }}
					/>
				</div>
			)),
		[activeMenuTab, handleToggle, sideMenuBtns]
	);

	const renderMenuScreens = menuObj[activeMenuTab];

	useOutsideClick(ref, () => {
		setIsFocus(false);
		setSearchedText('');
	});

	const handleKeyPress = useCallback(
		(event: KeyboardEvent<HTMLInputElement>) => {
			if (event.key.toLowerCase() === 'escape') {
				setIsFocus(false);
				const element = document.querySelector('#sidebar-search-input');
				if (element) {
					(element as HTMLInputElement).blur();
					setSearchedText('');
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<div className="left-sidebar" onKeyUp={handleKeyPress} ref={ref}>
			{(envelopeId || templateType === 'overlay') && (purpose !== 'selfsign' || templateType === 'editable') && (
				<SelectRecipient />
			)}
			<div ref={dropRef} className="left-sidebar-drop-container">
				{isOver ? (
					<DeleteNodesZone />
				) : (
					<>
						<div className="menu-tab__search">
							<Input
								inputType="text"
								handleChange={handleSearch}
								placeholder="Search Fields"
								allowClear={!!searchedText}
								handleClearField={handleClearField}
								suffixIcon={!searchedText ? 'ri-search-line' : ''}
								value={searchedText}
								handleFocus={() => setIsFocus(true)}
								label={''}
								id="sidebar-search-input"
							/>
						</div>
						{isFocus ? (
							<SearchedItems isFocus={isFocus}/>
						) : (
							<div className="menu-tab__container">
								<div className="menu-tab__button-wrapper">{renderMenuBtns}</div>
								<div className="menu-tab__fields">
									<div className="menu-tab__sideBar-data">
										{renderMenuScreens}
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
