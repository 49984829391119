import { v4 } from 'uuid';

import {
	IAllowedDragableNodesType,
	IFieldNodeType,
	IMenuTab,
	IMenuTabButtons,
	ITabMenu,
} from './types';

export const menuBtns: IMenuTabButtons[] = [
	{
		label: 'Custom',
		name: 'custom',
		className: 'ri-list-settings-line ri-lg',
	},
	{
		label: 'Standard',
		name: 'standard',
		className: 'ri-apps-2-line ri-lg',
	},
	{
		label: 'Compliance',
		name: 'compliance',
		className: 'ri-shield-check-line ri-lg',
	},
	{
		label: 'Questionnaire',
		name: 'questionnaire',
		className: 'ri-question-line ri-lg',
	},
	{
		label: 'Kyb',
		name: 'kyb',
		className: 'ri-shield-line ri-lg',
	},
	{
		label: 'Common Fields',
		name: 'Common Fields',
		className: 'ri-group-2-line ri-lg',
	},
];

export const AllowedResizableNodes: { [key in IFieldNodeType]: boolean } = {
	checkbox: false,
	radiogroup: false,
	date: true,
	email: true,
	hyperlink: false,
	initial: true,
	name: true,
	signature: true,
	text: true,
	title: true,
};

export const AllowedDraggableNodes: {
	[key in IAllowedDragableNodesType]: boolean;
} = {
	boolean: true,
	checkbox: true,
	date: true,
	email: true,
	hyperlink: true,
	initial: true,
	name: true,
	signature: true,
	text: true,
	title: true,
	radiogroup: true,
	rating: true,
	dropdown: true,
	tagbox: true,
	comment: true,
	multipletext: true,
	mcc: true,
	'investors-detail': true,
	matrixdropdown: true,
	fundInvestmentAmount: true
};

export const QuestionTypeNames = {
	text: 'Text',
	radiogroup: 'Radio group',
	rating: 'Rating',
	checkbox: 'Checkbox',
	dropdown: 'Dropdown',
	tagbox: 'Tag box',
	boolean: 'Boolean',
	file: 'File',
	imagepicker: 'Image picker',
	ranking: 'Ranking',
	comment: 'Comment',
	multipletext: 'Multiple text',
	paneldynamic: 'Dynamic panel',
	matrix: 'Matrix',
	matrixdropdown: 'Matrix dropdown',
	matrixdynamic: 'Matrix dynamic',
	html: 'Html',
	expression: 'Expression',
	image: 'Image',
	signaturepad: 'Signature pad',
	date: 'Date',
	email: 'Email',
	hyperlink: 'Hyperlink',
	initial: 'Initial',
	name: 'Name',
	signature: 'Signature',
	title: 'Title',
	mcc: 'MCC',
	'investors-detail': "Investors Details"

};

export const MenuItemIcons = {
	text: 'ri-align-right',
	radiogroup: 'ri-radio-button-line',
	rating: 'ri-star-half-s-line',
	checkbox: 'ri-checkbox-line',
	dropdown: 'ri-dropdown-list',
	tagbox: 'ri-bookmark-3-line',
	boolean: 'ri-toggle-line',
	file: 'ri-file-line',
	imagepicker: 'ri-image-add-fill',
	ranking: 'ri-sort-asc',
	comment: 'ri-align-right',
	multipletext: 'ri-align-right',
	paneldynamic: 'ri-brackets-line',
	matrix: 'ri-grid-line',
	matrixdropdown: 'ri-grid-line',
	matrixdynamic: 'ri-grid-line',
	html: 'ri-html5-line',
	expression: 'ri-links-line',
	image: 'ri-image-line',
	signaturepad: 'ri-pencil-line',
	date: 'ri-calendar-line',
	email: 'ri-mail-line',
	hyperlink: 'ri-link',
	initial: 'ri-pencil-line',
	name: 'ri-user-line',
	signature: 'ri-pencil-line',
	title: 'ri-file-user-line',
	mcc: 'ri-dropdown-list',
	'investors-detail': 'ri-align-right',
};

export const CommonFields = 'Common Fields';

export const InvestorColumnTabs = (source: IMenuTab): ITabMenu[] => [
	{
		createdAt: new Date(),
		icon: 'ri-align-right',
		label: 'Investor First Name',
		key: 'firstName',
		source: source,
		fieldType: 'text',
		updatedAt: new Date(),
		id: v4(),
		isEditable: false,
		isNew: false,
		questionType: 'investors-detail',
		index: null,
		choices: []
	},
	{
		createdAt: new Date(),
		icon: 'ri-align-right',
		label: 'Investor Last Name',
		key: 'lastName',
		source: source,
		fieldType: 'text',
		updatedAt: new Date(),
		id: v4(),
		isEditable: false,
		isNew: false,
		questionType: 'investors-detail',
		index: null,
		choices: []
	},
	{
		createdAt: new Date(),
		icon: 'ri-align-right',
		label: 'Investor Country Code',
		key: 'countryCode',
		source: source,
		fieldType: 'text',
		updatedAt: new Date(),
		id: v4(),
		isEditable: false,
		isNew: false,
		questionType: 'investors-detail',
		index: null,
		choices: []
	},
	{
		createdAt: new Date(),
		icon: 'ri-align-right',
		label: 'Investor Phone',
		key: 'phoneNumber',
		source: source,
		fieldType: 'text',
		updatedAt: new Date(),
		id: v4(),
		isEditable: false,
		isNew: false,
		questionType: 'investors-detail',
		index: null,
		choices: []
	},
	{
		createdAt: new Date(),
		icon: 'ri-align-right',
		label: 'Investor Email',
		key: 'email',
		source: source,
		fieldType: 'text',
		updatedAt: new Date(),
		id: v4(),
		isEditable: false,
		isNew: false,
		questionType: 'investors-detail',
		index: null,
		choices: []
	},
	{
		createdAt: new Date(),
		icon: 'ri-align-right',
		label: 'Shares Owned',
		key: 'shareOwned',
		source: source,
		fieldType: 'text',
		updatedAt: new Date(),
		id: v4(),
		isEditable: false,
		isNew: false,
		questionType: 'investors-detail',
		index: null,
		choices: []
	},
];

export const TEMPLATE_NAME_WANRING_STRING = {
	LABEL: "The document name you choose will be shown during e-signing. Please pick a clear name. If you don't change it, the original file name will be used."
}

export const ADD_RECIPIENT_WANRING_STRING = {
	HEADER: 'Recipient Missing',
	LABEL: "Please add recipient to proceed.",
}

export const DROP_ITEM_SOURCE = {
	questionaire: "questionaire"
}
