import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DropItemsState, IDropedItems, SelectedConfigNode, VALIDATION_TYPES, VALIDATION_FILED_NAME, currentNodePosition } from 'views';

export const useSetConfigFieldNode = () => {
	const setDropItems = useSetRecoilState(DropItemsState);
	const configItems = useRecoilValue(SelectedConfigNode);
	const getCurrentNodePosition = useRecoilValue(currentNodePosition);

	const handleConfigureNode = useCallback(
		(name: string, value: string | { value: string, label: string }) => {			
			if (configItems) {
				setDropItems((prev: IDropedItems[]) => {
					const prevState = JSON.parse(JSON.stringify(prev));
					const foundIndex = prevState.findIndex(
						(item: IDropedItems) => item.id === configItems.id
					);
					const foundItem = prevState[foundIndex];
					// Chaning the node values based on the validation type
					if(name === VALIDATION_FILED_NAME && typeof value === 'object'){
						const isNone =  value.label === "none";
						foundItem.fieldType = isNone ? 'text' : value.value;
						foundItem.label = isNone ? 'Text' : value.label;
						foundItem.metadata.editText = isNone ? 'Text' : value.label;
						foundItem.value = "";
						foundItem.metadata.validation = {
							type: value.value,
							value: '',
						}
					}
					if(typeof value === 'object' && VALIDATION_TYPES.includes(name)){
						foundItem.metadata.validation.value = value.value
					}

					foundItem.metadata[name] = value;
					
					if (
						foundItem.key === 'checkbox' || foundItem.type === 'checkbox' || foundItem.fieldType === 'checkbox' ||
						foundItem.key === 'radiogroup' || foundItem.type === 'radiogroup' || foundItem.fieldType === 'radiogroup'
					) {
						
						const {metadata, position} = foundItem
						const checkboxSize = metadata.fontSize?.value;
						const { containerWidth, containerHeight } = getCurrentNodePosition;
						metadata.height = checkboxSize;
						metadata.width = checkboxSize;
						// Parse width and height for calculations
						const nodeWidth = parseInt(`${checkboxSize}`.replace('px', ''));
						const nodeHeight = parseInt(`${checkboxSize}`.replace('px', ''));
						// Calculate new positions based on container size
						let xRight =
							(position.xLeft * containerWidth + nodeWidth) / containerWidth;
						let yBottom =
							(position.yTop * containerHeight + nodeHeight) / containerHeight;

						xRight = xRight === Infinity ? 0 : xRight;
						yBottom = yBottom === Infinity ? 0 : yBottom;
						// Update the new position for the found item
						foundItem.position = {
							...position,
							xRight,
							yBottom,
						};
					}
					prevState.splice(foundIndex, 1, foundItem);
					return prevState;
				});
			}
		},
		[configItems, setDropItems, getCurrentNodePosition]
	);
	return { handleConfigureNode };
};

export const useGetConfiguredNode = () => {
	const dropItems = useRecoilValue(DropItemsState);
	const configItems = useRecoilValue(SelectedConfigNode);

	const configuredNode = useCallback(
		(name: string) => {
			if (configItems) {
				const foundIndex = dropItems.findIndex(
					(item: IDropedItems) => item.id === configItems.id
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const foundItem: any = dropItems[foundIndex];
				return foundItem?.metadata?.[name] ?? null;
			}
		},
		[configItems, dropItems]
	);

	return { configuredNode };
};
