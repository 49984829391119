export const RADIO_APPEARANCE_OPTIONS = [
    { id: 'only-radiogroup', label: 'Only radiogroup' },
    { id: 'radiogroup-with-value', label: 'Radiogroup with value' },
    { id: 'only-option-value', label: 'Only option value' },
];

export const RADIO_APPEARANCE_CONFIG_KEYS = {
    onlyRadiogroup: 'only-radiogroup',
    radiogroupWithValue: 'radiogroup-with-value',
    onlyOptionVaue: 'only-option-value'
}

export const RADIOGROUP_CONFIG_KEYS = {
    radiogroupAppearance: 'radiogroupAppearance',
    radiogroupValue: 'radiogroupValue'
}

export const RADIOGROUP_TO_TEXT_SIZE_MAP : { [key: string]: string } = {
    "8px": "6px",
    "12px": "10px",
    "16px": "12px",
    "20px": "14px",
    "24px": "16px",
    "28px": "18px",
    "32px": "24px",
}

export const RADIOGROUP_DRAG_POSITION_WITH_LABEL: { [key: string]: { [key: string]: string } } = {
    "8px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "14px",
        "only-option-value": "6px"
    },
    "12px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "24px",
        "only-option-value": "12px"
    },
    "16px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "28px",
        "only-option-value": "14px"
    },
    "20px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "33px",
        "only-option-value": "15px"
    },
    "24px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "36px",
        "only-option-value": "16px"
    },
    "28px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "40px",
        "only-option-value": "16px"
    },
    "32px": { 
        "only-radiogroup": "0px",
        "radiogroup-with-value": "52px",
        "only-option-value": "20px"
    },
}

