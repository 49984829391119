import type { IFieldNodeType } from "./types";

import { useCallback } from "react";

import classNames from "classnames";
import { isSafari } from "utils";

export const useNodeType = () => {

  // Construct the checkbox class names
  const checkboxClass = classNames('checkbox-node', 'field-node', {
    'checkbox-node-safari': isSafari(), // Adds 'checkbox-node-safari' if the browser is Safari
  });

  const getInputForFieldNode = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (nodeType: IFieldNodeType, item: any, width: string | number) => {
      const { label, metadata } = item;
      switch (nodeType) {
        case "checkbox":
          return (
            <input
              className={checkboxClass}
              type="checkbox"
              checked={false}
              readOnly
              style={{ height: width+"px", width: width+"px"}}
              />
          );
        case "date":
          return <div className="date-node field-node">{label}</div>;
        case "email":
          return <div className="email-node field-node">{label}</div>;
        case "initial":
          return (
            <div className="initial-node field-node">
              {label} <i className="ri-download-line"></i>
            </div>
          );
        case "hyperlink":
          return (
            <div className="link-node field-node">
              {label} <i className="ri-share-box-line"></i>
            </div>
          );
        case "name":
          return <div className="name-node field-node">{label}</div>;
        case "signature":
          return (
            <div className="sign-node field-node">
              Sign<i className="ri-download-line"></i>
            </div>
          );
        case "text":
          return (
            <div className="text-node field-node text--alignement">
              {metadata?.editText || label}
            </div>
          );
        default:
          return (
            <div className="text-node field-node text--alignement">
              {metadata?.editText || label}
            </div>
          );
      }
    },
    [checkboxClass]
  );

  return { getInputForFieldNode };
};