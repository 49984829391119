import './radiogroup-configuration.scss'
import { useState, FC, useMemo, useCallback, useEffect, ChangeEvent } from 'react';

import { v4 } from 'uuid';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DropItemsState, IChoices, IDropedItems, SelectedConfigNode } from 'views/editor-dashboard';
import { currentRadioAppearanceState, IRadioAppearance, useSetConfigFieldNode } from 'views/config-items/store';
import { TAB_SOURCE } from 'constant';

import { RADIO_APPEARANCE_OPTIONS, RADIOGROUP_CONFIG_KEYS } from './store/constants';
import { RadioGroupAppearance } from './radiogroup-appearance';
import { RadioGrouCustomOptions } from './radiogroup-custom-options';
import { RadiogroupOptions } from './radiogroup-options';


interface IRadioGroupConfig {
    dropitems: IDropedItems[];
    selectedDropItem: IDropedItems | undefined;
    radiogroupOptionChoices: IChoices[] | undefined
}

const RadiogroupConfiguration: FC<IRadioGroupConfig> = ({ dropitems, selectedDropItem, radiogroupOptionChoices }) => {
    // this state contaain local current selected appearance
    const [appearanceOption, setAppearanceOption] = useState<IRadioAppearance>({});
    const { handleConfigureNode } = useSetConfigFieldNode();

    const setDropItems = useSetRecoilState(DropItemsState);
    const setActiveNode = useSetRecoilState(SelectedConfigNode);
    const [currentAppearance, setCurrentAppearance] = useRecoilState(currentRadioAppearanceState);
    // this state contaain local current selected appearance

    const removeCurrentSelectedAppearance = useCallback(() => {
        setCurrentAppearance((prev) => {
            const prevState = JSON.parse(JSON.stringify(prev));
            const questionKey = selectedDropItem?.name as string
            delete prevState[questionKey];
            return prevState;
        })
    }, [setCurrentAppearance])

    const onAppearanceChange = useCallback((value: string, isNew = false) => {
        handleConfigureNode(RADIOGROUP_CONFIG_KEYS.radiogroupAppearance, value);
        if(isNew) {
            removeCurrentSelectedAppearance();
        } else {
            setAppearanceOption({ [selectedDropItem?.name as string] : value});
        }
    }, [handleConfigureNode])

    const applySettingToAll = () => {
        setDropItems((prev: IDropedItems[]) => {
            const prevState = JSON.parse(JSON.stringify(prev));
            prevState.map((item: IDropedItems) => {
                const questionKey = selectedDropItem?.name as string
                if(item.name === questionKey && currentAppearance[questionKey]){
                    item.metadata.radiogroupAppearance = currentAppearance[questionKey];
                }
            })
            return prevState;
        })
    }

    const handleSwitch = useCallback((status: boolean) => {
        if(status){

            setCurrentAppearance(appearanceOption);
        }
        else {
            removeCurrentSelectedAppearance();
        }
    }, [appearanceOption, setCurrentAppearance, removeCurrentSelectedAppearance])

    useEffect(() => {
        if(currentAppearance[selectedDropItem?.name as string]){
            setCurrentAppearance(appearanceOption)
        }
    },[appearanceOption, selectedDropItem, setCurrentAppearance]);

    useEffect(() => {
            applySettingToAll();
    },[currentAppearance]);

    const selectionRadiooptionValue = selectedDropItem?.metadata.radiogroupValue || radiogroupOptionChoices?.[0]?.text || '';
    const selectedCheckboxApearance = selectedDropItem?.metadata.radiogroupAppearance || '';

    const filterSameRadiobuttons = useMemo(() => {
        return dropitems.filter((item: IDropedItems) => item.name === selectedDropItem?.name && item.fieldType === 'radiogroup')
    }, [dropitems, selectedDropItem]);

    const onAddOption = useCallback(() => {
        const allDropItems = structuredClone(dropitems);
        const foundIndex = allDropItems.findIndex(
            (item: IDropedItems) => item.id === selectedDropItem?.id
        );
        const dropItem = structuredClone(allDropItems[foundIndex]);
        if(dropItem && dropItem.position) {

            const { xLeft } = dropItem.position
            const newDropItem = {
                ...dropItem,
                id: v4(),
                position : {
                    ...dropItem?.position,
                    xLeft: xLeft+0.01
                }
            }
            allDropItems.push(newDropItem);
            setDropItems(allDropItems);
            setActiveNode({
                fieldType: newDropItem.fieldType || 'radiogroup',
                index: newDropItem.pageNumber || 0,
                id: newDropItem.id,
                isEditable: newDropItem.isEditable,
                recipient: newDropItem.recipient,
                source: newDropItem.source,
            });
        }
    }, [setDropItems, dropitems, setActiveNode, selectedDropItem]);

    const handleCustomOptionChange = (item: IDropedItems) => {
        setActiveNode({
            fieldType: item.fieldType || 'radiogroup',
            index: item.pageNumber || 0,
            id: item.id,
            isEditable: item.isEditable,
            recipient: item.recipient,
            source: item.source,
        });
    };

    const onInputFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleConfigureNode(RADIOGROUP_CONFIG_KEYS.radiogroupValue, event.target.value)
    }

    const onOptionChange = useCallback((value: string) => {
        handleConfigureNode(RADIOGROUP_CONFIG_KEYS.radiogroupValue, value);
    }, [handleConfigureNode])

    useEffect(() =>{
        if(selectedDropItem?.source === TAB_SOURCE.QUESTIONAIRE) {
            setTimeout(() => {
                if(!selectedDropItem?.metadata.checkboxValue && radiogroupOptionChoices?.[0]?.text){
                    onOptionChange(radiogroupOptionChoices?.[0]?.text);
                }
                if(!selectedDropItem?.metadata.checkboxAppearance && RADIO_APPEARANCE_OPTIONS[0]?.id){
                    onAppearanceChange(RADIO_APPEARANCE_OPTIONS[0]?.id, true);
                }
            }, 100);
        }
    },[selectedDropItem?.metadata, onOptionChange, onAppearanceChange, radiogroupOptionChoices]);

    const isSourceQuestionaire = useMemo(() => selectedDropItem?.source === TAB_SOURCE.QUESTIONAIRE, [selectedDropItem?.source])
    
    return (
        <div className="radiobuttonconfig">
            {
                !isSourceQuestionaire
                &&
                <div className="radiobuttonconfig__header">
                    <span>Option List & Values</span>
                    <button className="add-option" onClick={onAddOption}>Add option</button>
                </div>
            }
            
            <div className="options">
                {
                    isSourceQuestionaire ?
                    <RadiogroupOptions 
                        options={radiogroupOptionChoices}
                        label={selectedDropItem?.label}
                        selectedOption={selectionRadiooptionValue}
                        onChange={onOptionChange}
                    />
                    :
                        <RadioGrouCustomOptions 
                        filterSameRadiobuttons={filterSameRadiobuttons}
                        selectedDropItem={selectedDropItem}
                        handleOptionChange={handleCustomOptionChange}
                        onInputFieldChange={onInputFieldChange}
                    />
                }
               
            </div>
            <RadioGroupAppearance 
                onAppearanceChange={onAppearanceChange} 
                handleSwitch={handleSwitch} 
                isSourceQuestionaire={isSourceQuestionaire}
                appearance={selectedCheckboxApearance} 
                isAllSelected={!!currentAppearance[selectedDropItem?.name as string]}
            />
        </div>
    );
};

export default RadiogroupConfiguration;
