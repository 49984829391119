/**
 * The `Memory` class serves as a utility to store and retrieve a single static `uploadTimestamp` value.
 *
 * This class encapsulates static methods to set and get the `uploadTimestamp`,
 * which can be used across the application without needing to create an instance.
 */
export class Memory {
	// A static property to store the upload timestamp as a number
	private static uploadTimestamp: number;

	/**
	 * Sets the `uploadTimestamp` value.
	 *
	 * @param {number} timestamp - The timestamp value to store.
	 * @returns {number} - The newly set timestamp value.
	 */
	static setTimeStamp(timestamp: number): number {
		Memory.uploadTimestamp = timestamp; // Store the provided timestamp in the static property
		return Memory.uploadTimestamp; // Return the stored timestamp for convenience
	}

	/**
	 * Retrieves the current `uploadTimestamp` value.
	 *
	 * @returns {number | undefined} - The stored timestamp value or `undefined` if it hasn't been set yet.
	 */
	static getTimeStamp(): number | undefined {
		return Memory.uploadTimestamp; // Return the current value of the static property
	}
}
