/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import * as ReactDOM from 'react-dom/client';
import reactToWebComponent from 'react-to-webcomponent';

import App from './App';

// Replace with your GA4 Measurement ID
const GA_MEASUREMENT_ID = 'G-DFZ2ND2B4Z';

// Add the GA4 script to the document head
const initializeGA = () => {
	const script = document.createElement('script');
	script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
	script.async = true;
	document.head.appendChild(script);

	script.onload = () => {
		// Initialize GA4 after the script has loaded
		(window as any).dataLayer = (window as any).dataLayer || [];
		function gtag(...args: any[]) {
			(window as any).dataLayer.push(args);
		}
		gtag('js', new Date());
		gtag('config', GA_MEASUREMENT_ID);
		gtag('config', GA_MEASUREMENT_ID, {
			debug_mode: true,
		});
	};
};

// Initialize GA
initializeGA();

const SimpliciSignContainer = reactToWebComponent(
	App as any,
	React as any,
	ReactDOM as any,
	{
		props: {
			url: 'string',
			env: 'string',
			submit_esign: Function,
			hide_logo: 'boolean',
			hide_user: 'boolean',
		},
	}
);

customElements.define('simplici-sign', SimpliciSignContainer as any);
