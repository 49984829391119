
import React from 'react';

import { IChoices } from 'views/editor-dashboard';

interface RadiogroupOptionsProps {
    options: IChoices[] | undefined;
    label: string | undefined
    selectedOption: string | undefined;
    onChange: (selected: string) => void;
}

export const RadiogroupOptions: React.FC<RadiogroupOptionsProps> = ({ options, label, selectedOption, onChange }) => {
    
    return (
        <div className="radio-options">
            <span className='radiobuttonconfig__header'>{label}?</span>
            <div className='radiobuttonconfig__option-container'>
                {options?.map((option) => (
                    <label key={option.value}>
                        <input
                            type="radio"
                            value={option.text}
                            checked={selectedOption === option.text}
                            onChange={() => onChange(option.text)}
                        />
                        {option.text}
                    </label>
                ))}
            </div>
        </div>
    );
};


