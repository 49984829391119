
import React, { useCallback, useEffect, useState } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { DropItemsState, IChoices, IDropedItems } from 'views/editor-dashboard';
import { currentCheckboxAppearanceState, ICheckboxAppearance, useSetConfigFieldNode } from 'views/config-items/store';

import { APPEARANCE_OPTIONS, CHECKBOX_CONFIG_KEYS, CheckboxAppearance, CheckboxOptions } from './';

interface ICheckboxConfigurations {
    selectedDropitem: IDropedItems | undefined,
    checkboxOptionChoices: IChoices[] | undefined
}

const CheckboxConfiguration: React.FC<ICheckboxConfigurations> = ({ selectedDropitem, checkboxOptionChoices }) => {

    const { handleConfigureNode } = useSetConfigFieldNode();

    const setDropItems = useSetRecoilState(DropItemsState);
    const [currentAppearance, setCurrentAppearance] = useRecoilState(currentCheckboxAppearanceState);
    // this state contaain local current selected appearance
    const [appearanceOption, setAppearanceOption] = useState<ICheckboxAppearance>({});

    const removeCurrentSelectedAppearance = useCallback(() => {
        setCurrentAppearance((prev) => {
            const prevState = JSON.parse(JSON.stringify(prev));
            const questionKey = selectedDropitem?.key as string
            delete prevState[questionKey];
            return prevState;
        })
    }, [setCurrentAppearance])

    const onAppearanceChange = useCallback((value: string, isNew = false) => {
        handleConfigureNode(CHECKBOX_CONFIG_KEYS.checkboxAppearance, value);
        if(isNew) {
            removeCurrentSelectedAppearance();
        } else {
            setAppearanceOption({ [selectedDropitem?.key as string] : value});
        }
    }, [handleConfigureNode])

    const onOptionChange = useCallback((value: string) => {
        handleConfigureNode(CHECKBOX_CONFIG_KEYS.checkboxValue, value);
    }, [handleConfigureNode])

    const applySettingToAll = () => {
        setDropItems((prev: IDropedItems[]) => {
            const prevState = JSON.parse(JSON.stringify(prev));
            prevState.map((item: IDropedItems) => {
                const questionKey = selectedDropitem?.key as string
                if(item.key === questionKey && currentAppearance[questionKey]){
                    item.metadata.checkboxAppearance = currentAppearance[questionKey];
                }
            })
            return prevState;
        })
    }
    
    const handleSwitch = useCallback((status: boolean) => {
        if(status){
            setCurrentAppearance(appearanceOption);
        }
        else {
            removeCurrentSelectedAppearance();
        }
    }, [appearanceOption, setCurrentAppearance, removeCurrentSelectedAppearance])

    useEffect(() => {
        if(currentAppearance[selectedDropitem?.key as string]){
            setCurrentAppearance(appearanceOption)
        }
    },[appearanceOption, selectedDropitem, setCurrentAppearance]);

    useEffect(() => {
            applySettingToAll();
    },[currentAppearance])

    const selectionCheckboxValue = selectedDropitem?.metadata.checkboxValue || checkboxOptionChoices?.[0]?.text || '';
    const selectedCheckboxApearance = selectedDropitem?.metadata.checkboxAppearance || APPEARANCE_OPTIONS[0]?.id || '';
    
    useEffect(() =>{
        setTimeout(() => {
            if(!selectedDropitem?.metadata.checkboxValue && checkboxOptionChoices?.[0]?.text){
                onOptionChange(checkboxOptionChoices?.[0]?.text);
            }
            if(!selectedDropitem?.metadata.checkboxAppearance && APPEARANCE_OPTIONS[0]?.id){
                onAppearanceChange(APPEARANCE_OPTIONS[0]?.id, true);
            }
        }, 100);
    },[selectedDropitem?.metadata, onOptionChange, onAppearanceChange, checkboxOptionChoices]);

    return (
        <div className="configuration">
            <CheckboxOptions 
                options={checkboxOptionChoices}
                label={selectedDropitem?.label}
                selectedOption={selectionCheckboxValue}
                onChange={onOptionChange}
            />
            { selectedDropitem?.questionType === 'checkbox' && <CheckboxAppearance 
                onAppearanceChange={onAppearanceChange} 
                handleSwitch={handleSwitch} 
                appearance={selectedCheckboxApearance} 
                isAllSelected={!!currentAppearance[selectedDropitem?.key as string]}
            />}
        </div>
    );
};

export default CheckboxConfiguration;
