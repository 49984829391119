import React from 'react';

import { ReactSwitch } from "@storybook";

import { APPEARANCE_OPTIONS } from './store/constants';

interface ICheckboxAppearance {
    onAppearanceChange: (selected: string) => void;
    handleSwitch: (checked: boolean) => void;
    appearance: string | undefined;
    isAllSelected: boolean;
}

export const CheckboxAppearance: React.FC<ICheckboxAppearance> = ({ onAppearanceChange, handleSwitch, appearance, isAllSelected }) => {
    return (
        <div className="appearance">
            <span className='appearance__heading'>Appearance</span>
            <div className='configuration__option-container'>
                {APPEARANCE_OPTIONS.map((option) => (
                    <label key={option.id}>
                        <input
                            type="radio"
                            value={option.label}
                            checked={appearance === option.id}
                            onChange={() =>onAppearanceChange(option.id)}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <label className="apply-setting">
                Apply appearance setting to all options for this question
                <ReactSwitch id={""} checked={isAllSelected} handleChange={handleSwitch} />
            </label>
        </div>
    );
};
