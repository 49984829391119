import React from 'react';

import { ReactSwitch } from "@storybook";

import { RADIO_APPEARANCE_CONFIG_KEYS, RADIO_APPEARANCE_OPTIONS } from './store/constants';

interface IRadioGroupAppearance {
    onAppearanceChange: (selected: string) => void;
    handleSwitch: (checked: boolean) => void;
    appearance: string | undefined;
    isAllSelected: boolean;
    isSourceQuestionaire: boolean;
}

export const RadioGroupAppearance: React.FC<IRadioGroupAppearance> = ({ onAppearanceChange, handleSwitch, appearance, isAllSelected, isSourceQuestionaire }) => {
    if(appearance === '') {
       setTimeout(() => {
        onAppearanceChange(RADIO_APPEARANCE_OPTIONS[0]?.id as string)
       }, 300);
    }

    return (
        <div className="appearance">
            <span className='radiobuttonconfig__header'>Appearance</span>
            <div className='radiobuttonconfig__option-container'>
                {RADIO_APPEARANCE_OPTIONS.filter((option) => option.id !== RADIO_APPEARANCE_CONFIG_KEYS.onlyOptionVaue || isSourceQuestionaire ).map((option) => (
                    <label key={option.id} className='option-value-css'>
                        <input
                            type="radio"
                            value={option.label}
                            checked={appearance === option.id}
                            onChange={() => onAppearanceChange(option.id)}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
            <label className="apply-setting">
                Apply appearance setting to all options for this question
                <ReactSwitch id={""} checked={isAllSelected} handleChange={handleSwitch} />
            </label>
        </div>
    );
};
