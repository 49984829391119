import { FC } from 'react';

import './loader.scss';
import { useRecoilValue } from 'recoil';
import { organizationDetailState } from 'states';

interface ILoaderDimension {
	dimension?: number;
	className?: 'loader-blue' | 'loader-white' | 'loader-pink';
  type?: 'gif' | 'circle'; // Optional, can be 'gif', 'circle', or undefined
}

export const Loader: FC<ILoaderDimension> = ({
	dimension,
	className = 'loader-blue',
	type = 'gif', // Set default 'type' to 'gif'
}) => {
	let _dimension = dimension;
	if (!dimension) {
		_dimension = 54;
	}
	const organizationalData = useRecoilValue(organizationDetailState);

	// Component rendering based on the 'type' prop
	// Checks if the 'type' is 'circle' to render the circle loader

	if (type === 'circle') {
		return (
			// Wrapper div for the circular loader with a specific CSS class for styling
			<div className="esign-circle-loader">
				{/* 
        The inner div is styled dynamically with 'width' and 'height' set 
        based on the '_dimension' variable. The 'className' is appended to 
        the loader for any additional custom styling.
      */}
				<div
					style={{ width: _dimension, height: _dimension }} // Sets size dynamically
					className={`loader ${className}`} // Combines loader class with any additional className passed as a prop
				/>
			</div>
		);
	}

	return (
		<div className="esign-circle-loader">
			{organizationalData?.loader && organizationalData.loaderWhitelabel ? (
				<img
					style={{ width: _dimension, height: _dimension }}
					src={organizationalData?.loader}
					alt="Gif Loader"
				/>
			) : (
				<div
					style={{ width: _dimension, height: _dimension }}
					className={`loader ${className}`}
				/>
			)}
		</div>
	);
};
