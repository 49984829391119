import { useCallback, useMemo } from 'react';

import { Button, Loader } from '@storybook';
import { useRecoilValue } from 'recoil';
import {
	IsFontsLoadedState,
	PrepareDashboardState,
	SignDocSelector,
	useNodeIterator,
} from 'views';
import { useNotification } from 'hooks';
import { formatBase64 } from 'utils';
import { selfSignSelector } from 'views/self-sign/store';
import { EVENTS_TRACKING, timestamp, useTrackEvents } from 'helpers';

import {
	activeTabState,
	CreateSignState,
	hasKey,
	ICreateSignPayload,
	useResetCreateSign,
	useSetCreateSign,
} from '../store';

export const CreateSignFooter = () => {
	const isFontsLoaded = useRecoilValue(IsFontsLoadedState);
	const signData = useRecoilValue(CreateSignState);
	const activeTab = useRecoilValue(activeTabState);
	const { submitSign, isLoading } = useSetCreateSign();
	const { nodeIterator } = useNodeIterator();
	const { envelopeId, recipient } = useRecoilValue(SignDocSelector);
	const { selfSigner } = useRecoilValue(selfSignSelector);
	const { reset } = useResetCreateSign();
	const signature = signData[activeTab];
	const { _id: selfSignEnvelopeId } = useRecoilValue(PrepareDashboardState);
	const { errorNotification } = useNotification();
	const { trackEvents } = useTrackEvents();

	const getSize = useCallback((base64String: string) => {
		const stringLength = base64String.length - 'data:image/png;base64,'.length;
		const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
		const sizeInKb = sizeInBytes / 1000;
		return sizeInKb;
	}, []);

	const handleCreateSign = useCallback(async () => {
		if (isLoading) {
			return;
		}
		try {
			const payload: Partial<ICreateSignPayload> = {};
			if (signature.sign) {
				payload.signature = {
					base64File: formatBase64(signature.sign),
					name: `${recipient.fullName}-sign`,
					size: getSize(signature.sign),
					type: activeTab,
				};
			}
			if (signature.initials) {
				payload.initial = {
					base64File: formatBase64(signature.initials),
					name: `${recipient.fullName}-initial`,
					size: getSize(signature.initials),
					type: activeTab,
				};
			}
			if (!signData.isEdit) {
				payload.envelopeId = envelopeId ?? selfSignEnvelopeId;
				payload.recipientId = recipient._id ?? selfSigner?._id;
			}
			/**
			 * logs added for production debugging
			 * */
			// eslint-disable-next-line no-console
			console.log("create sign payload", payload);
			const status = await submitSign(payload);
			if (status) {
				trackEvents(EVENTS_TRACKING.AGREEMENT_SIGNED,{
					timestamp: timestamp,
					status
				})
				reset();
			}
			setTimeout(() => {
				nodeIterator();
			}, 500);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			errorNotification('Something went wrong. Please try again');
		}
	}, [
		activeTab,
		envelopeId,
		errorNotification,
		getSize,
		isLoading,
		nodeIterator,
		recipient,
		reset,
		selfSignEnvelopeId,
		selfSigner?._id,
		signData,
		signature.initials,
		signature.sign,
		submitSign,
		trackEvents
	]);

	const isDisabled = useMemo((): boolean => {
		if (!isFontsLoaded) {
			return true;
		}
		const { consent, fullName, initials } = signData;
		let isSigned = false;
		if (hasKey(signData, activeTab)) {
			isSigned = signData[activeTab]['sign'] || signData[activeTab]['initials'];
		}
		if (consent && fullName.trim() && initials.trim() && isSigned) {
			return false;
		}
		return true;
	}, [activeTab, isFontsLoaded, signData]);

	const handleSignatureLabel = useMemo(() => {
		return isLoading ? (
			<Loader className="loader-blue" dimension={20} type="circle" />
		) : (
			'Create Signature'
		);
	}, [isLoading]);

	return (
		<div className="create-sign-footer">
			<div className="create-sign-footer__btn-wrapper">
				<Button
					className="esign-button__filled esign-button__filled--primary esign-button__large esign-button__block create-sign__btn"
					label={handleSignatureLabel}
					handleClick={handleCreateSign}
					disabled={isDisabled || isLoading}
				/>
			</div>
		</div>
	);
};
