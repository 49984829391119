import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Loader } from '@storybook';
import {
	APP_ROUTES,
	ConfigDocumentState,
	DropItemsState,
	IDropedItems,
	IRecipientUser,
	IsConfigDrawerOpen,
	IsLoadingState,
	IsOriginalCopy,
	IsShowMessage,
	IsUndoModal,
	RecipientsState,
	SelectedConfigNode,
	TemplateSourceState,
	configDocTitleState,
	counterSecond,
	isSigningOrderEnabledState,
	resetColorsSelected,
	signerRecipientsState,
	templateTypeState,
	useInvitePayload,
	usePrepareOverlay,
	useSetConfigDoc,
} from 'views';
import { useNotification, useUpdateDeviceInfo } from 'hooks';
import { GetQueryParams, isValidLink } from 'utils';
import { ENVELOPE_PURPOSE, MESSAGE, TEMPLATE_SOURCE } from 'constant';
import { UndoModal } from 'views/undo-modal';
import {
	EnvelopePurposeState,
	isShowConfigDocScreen,
	isShowSelfSignDocScreen,
	webComponentEnvelopeIdState,
} from 'states';

import { WarningModal } from '../Warning-Modal';

export const ConfigFooter = () => {
	const setActiveNode = useSetRecoilState(SelectedConfigNode);
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const { handleSaveTemplate, handleSaveEnvelope } = useSetConfigDoc();
	const { errorNotification } = useNotification();
	const signerRecipients = useRecoilValue(signerRecipientsState);
	const navigate = useNavigate();
	const { patchDeviceInfo } = useUpdateDeviceInfo();
	const { URL_LINK_MESSAGE, OPTIONAL_FIELD_MESSAGE } = MESSAGE;
	const setShowMessage = useSetRecoilState(IsShowMessage);
	const templateType = useRecoilValue(templateTypeState);
	const { patchOverlaytemplate } = usePrepareOverlay();
	const recipients = useRecoilValue(RecipientsState);
	const signOrder = useRecoilValue(isSigningOrderEnabledState);
	const configDocTitle = useRecoilValue(configDocTitleState);
	const [isLoading, setIsLoading] = useRecoilState(IsLoadingState);
	const [isUndoModal, setIsUndoModal] = useRecoilState(IsUndoModal);
	const dropNodeItems = useRecoilValue(DropItemsState);
	const isOriginalCopyToggle = useRecoilValue(IsOriginalCopy);
	const envelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const [showConfigDoc, setShowEditorScreen] = useRecoilState(
		isShowConfigDocScreen
	);
	const setShowSelfSign = useSetRecoilState(isShowSelfSignDocScreen);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const templateSource = useRecoilValue(TemplateSourceState)
	const { data: documents } = useRecoilValue(ConfigDocumentState);
	// local state
	const [timeoutApiCall, setTimeoutApiCall] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(false);

	const templateId = GetQueryParams('templateId');

	const { saveTemplatePayload } = useInvitePayload({
		envelopeId: envelopeId || templateId,
	});

	const handleClose = useCallback(() => {
		if (showConfigDoc) {
			setShowEditorScreen(false);
			setShowSelfSign(false);
		} else {
			navigate(-1);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, showConfigDoc]);

	const handleSaveLabel = useMemo(() => {
		if (isLoading) {
			return <Loader className="loader-blue" dimension={14} type='circle' />;
		}		
		if (purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT || !envelopeId) {
			return 'Save and Close'
		}
		return  'Send'
	}, [envelopeId, isLoading, purpose]);

	const validateLink = useCallback(
		(dropItems: IDropedItems[]) => {
			const invalidLinks = dropItems.filter(
				(dropItem: IDropedItems) =>
					dropItem.fieldType === 'hyperlink' &&
					(!dropItem.metadata?.url || !isValidLink(dropItem.metadata.url))
			);
			if (!!invalidLinks.length && invalidLinks[0]) {
				setActiveNode({
					fieldType: invalidLinks[0].fieldType,
					index: invalidLinks[0].pageNumber,
					id: invalidLinks[0].id,
					isEditable: true,
					recipient: invalidLinks[0].recipient,
				});
				setIsDrawerOpen(true);
			}
			return !invalidLinks.length;
		},
		[setActiveNode, setIsDrawerOpen]
	);

	const handleCheckPreInviteValidations = useCallback(() => {
		const isRecipientDoesNotExist = dropNodeItems.filter(
			({recipient, source}) => {
			if (source !== "common") {
					return !recipient
				}else {
					return false
				}			
			}
		);
		// In case of multisignAgreement isRecipient exist condition will not check
		if (isRecipientDoesNotExist.length > 0 && purpose!=='multisignAgreement' && templateSource !== TEMPLATE_SOURCE.ONBOARDING ) {
			errorNotification(
				`Please assign recipient for ${isRecipientDoesNotExist
					.map(({ label }) => label.toLowerCase())
					.join(', ')} node(s)`
			);
			return false;
		}
		const recipientsWithNonReadOnlyNodes = dropNodeItems
			.filter(
				(item) =>
					item.metadata.required === true ||
					item.metadata.required === undefined
			)
			.map((item: IDropedItems) => item.recipient);
		const recipientNamesWithoutNodes: string[] = [];
		signerRecipients.forEach((_recipient) => {
			if (!recipientsWithNonReadOnlyNodes.includes(_recipient.id || '')) {
				recipientNamesWithoutNodes.push(_recipient.fullName ?? _recipient.title);
			}
		});
		if (recipientNamesWithoutNodes.length !== 0) {
			errorNotification(
				`Please assign at least one non optional field for ${recipientNamesWithoutNodes.join(
					', '
				)}`
			);
			return false;
		}
		setIsLoading(true);
		return true;
	}, [dropNodeItems, purpose, templateSource, signerRecipients, setIsLoading, errorNotification]);

	const configureOverlayPayload = useMemo(() => {
		const tempRecipient = structuredClone(recipients).map(
			({ message, action, id, title, originalCopy, colorCode, color }: IRecipientUser) => {
				const data: { [key: string]: string | boolean | undefined } = {
					action,
					title,
					originalCopy: isOriginalCopyToggle ? originalCopy : true,
					colorCode: colorCode ?? color
				};
				if (id && !id.startsWith('temp')) {
					data._id = id;
				}
				if (message) {
					data.message = message;
				}
				return data;
			}
		);

		const { documents } = saveTemplatePayload;
		const payload = {
			documents,
			recipients: tempRecipient,
			signOrder,
			status: 'saved',
			name: configDocTitle?.name,
		};
		return payload;
	}, [configDocTitle?.name, recipients, saveTemplatePayload, signOrder, isOriginalCopyToggle]);

	const getTemplateName = useCallback((changeTemplateName: boolean | undefined) => {
		return changeTemplateName ? documents[0]?.name.replace(".pdf", "") : saveTemplatePayload.name;
	},[documents, saveTemplatePayload])

	const handleSave = useCallback((changeTemplateName?: boolean) => {
		
		setIsLoading(false);
		const isRequired = dropNodeItems.some((item) => {
			return (
				item.metadata.required === true || item.metadata.required === undefined
			);
		});

		const isUrlValid = validateLink(dropNodeItems);
		if (!isUrlValid) {
			setTimeoutApiCall(false);
			errorNotification(URL_LINK_MESSAGE);
			setShowMessage(true);
			return;
		}
		if (!isRequired) {
			setTimeoutApiCall(false);
			errorNotification(OPTIONAL_FIELD_MESSAGE);
			return;
		}
		if (envelopeId) {
			const isValid = handleCheckPreInviteValidations();
			if (isValid) {
				patchDeviceInfo('sender');
				handleSaveEnvelope(saveTemplatePayload, undefined, true)
					.then((saveSuccessful) => {
						if (saveSuccessful) {
							resetColorsSelected();
						}
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
			return;
		}
		if (templateType === 'overlay' && !envelopeId) {
			const isValid = handleCheckPreInviteValidations();
			if (isValid) {
				const templateName = getTemplateName(changeTemplateName);
				patchOverlaytemplate({...configureOverlayPayload, name: templateName}, templateId ?? '')
					.then((status) => {
						if (status) {
							navigate(`/${APP_ROUTES.CONFIGSUCCESS}`);
						}
					})
					.finally(() => {
						setIsLoading(false);
					});
			}
			return;
		}
		setIsLoading(true);
		const templateName = getTemplateName(changeTemplateName);
		handleSaveTemplate({ ...saveTemplatePayload, name: templateName, status: 'saved' }).finally(
			() => {
				setIsLoading(false);
			}
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dropNodeItems,
		validateLink,
		envelopeId,
		templateType,
		handleSaveTemplate,
		saveTemplatePayload,
		handleCheckPreInviteValidations,
		patchDeviceInfo,
		handleSaveEnvelope,
		patchOverlaytemplate,
		configureOverlayPayload,
		templateId,
	]);

	const onSaveAndCloseClick = useCallback(() => {
		setIsLoading(false);
		if((!configDocTitle.isEdited && "new template" === saveTemplatePayload.name.toLowerCase())) {
			setShowWarningModal(true);
			return ;
		}
		handleSave();
	},[saveTemplatePayload, handleSave, configDocTitle, setIsLoading])

	const onProceedClick = useCallback(() => {
		setShowWarningModal(false);
		handleSave(true);
	}, [setShowWarningModal, handleSave])

	const onWarningModalClose = useCallback(() => {
		setTimeoutApiCall(false);
		setShowWarningModal(false);
	}, [setTimeoutApiCall, setShowWarningModal])

	const handleSubmitting = useCallback(() => {
		if (purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
			handleSave();
		}else {
			setTimeoutApiCall(false);
			if (!handleCheckPreInviteValidations()) {
				setTimeoutApiCall(false);
			} else if (envelopeId && validateLink(dropNodeItems)) {
				setIsUndoModal(true);
				setIsLoading(true);
				setTimeout(() => {
					if (timeoutApiCall) handleSave();
				}, counterSecond * 1000);
			} else {
				setTimeoutApiCall(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropNodeItems, envelopeId, handleCheckPreInviteValidations, handleSave, purpose, timeoutApiCall, validateLink]);

	useEffect(() => {
		if (!isUndoModal && isLoading) {
			setTimeoutApiCall(true);
			if (timeoutApiCall) onSaveAndCloseClick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUndoModal, timeoutApiCall]);

	return (
		<div className="footer-btn">
			<UndoModal setLoading={setIsLoading} width={'75%'} />
			{showWarningModal && <WarningModal showModal={showWarningModal} onClose={onWarningModalClose} onProceed={onProceedClick} />}
			{envelopeId || templateType === 'overlay' ? (
				<>
					<Button
						label="Back"
						handleClick={handleClose}
						className="esign-button__filled esign-button__filled--secondary esign-button__large"
					/>
					<Button
						disabled={!dropNodeItems.length}
						label={handleSaveLabel}
						handleClick={handleSubmitting}
						className="esign-button__filled esign-button__filled--primary esign-button__large"
					/>
				</>
			) : (
				<Button
					disabled={!dropNodeItems.length}
					label={handleSaveLabel}
					handleClick={onSaveAndCloseClick}
					className="esign-button__filled esign-button__filled--primary esign-button__large"
				/>
			)}
		</div>
	);
};
