/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react';

import AppleLogin from 'react-apple-login';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Button, Loader } from '@storybook';
import { useUpdateDeviceInfo, useWebAuthentication } from 'hooks';
import { DocumentProcessingState, ENV_STATE, EnvState } from 'states';
import {
	selectedNodeState,
	CompiledSigningNodesState,
	usePublishSignedDoc,
	SignDocSelector,
	useNodeIterator,
	IRecipient,
	zoomContentSelector,
	IAuthUserAuthType,
	CurrentUserAllTabs,
	WebAuthCounter,
} from 'views';
import {
	IsBiomentricSupported,
	formatBase64,
	isIOS,
	isVersionGreaterThan,
} from 'utils';
import {
	IFacialBioMetricDoneState,
	IsFacialModalOpen,
} from 'views/facial-biometric/store';
import { BUTTON_LABEL, ENVELOPE_PURPOSE } from 'constant';

//Footer Component
export const Footer = () => {
	// Recoils
	const signingNodes = useRecoilValue(CompiledSigningNodesState);
	const {
		envelopeId,
		recipient = {} as IRecipient,
		purpose,
		reviewSign,
	} = useRecoilValue(SignDocSelector);
	const processingDoc = useRecoilValue(DocumentProcessingState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const resetSelectedNode = useResetRecoilState(selectedNodeState);
	const [facialBiometricStatus, setFacialBiometricStatus] = useRecoilState(
		IFacialBioMetricDoneState
	);
	const authUserAuthType = useRecoilValue(IAuthUserAuthType);
	const setFacialModal = useSetRecoilState(IsFacialModalOpen);
	const currentuserTabs = useRecoilValue(CurrentUserAllTabs);
	const [isLoaded, setIsLoaded] = useState(true);
	const isGreater = isVersionGreaterThan('17.4.0');
	const webAuthCancelCounter = useRecoilValue(WebAuthCounter);
	const env = useRecoilValue(EnvState);
	const isIPhone = useMemo(() => {
		const iphone = isIOS();
		if (!iphone) {
			return false;
		}
		if (!isGreater && webAuthCancelCounter >= 1) {
			return true;
		} else if (isGreater && webAuthCancelCounter >= 2) {
			return true;
		}
		return false;
	}, [isGreater, webAuthCancelCounter]);

	const allSigningNodes = useMemo(() => {
		const getAllSignNodes = currentuserTabs.filter(
			(tab) => tab.type === 'signature' || tab.type === 'initial'
		);
		const assignNodes =
			purpose === 'multisignAgreement' &&
			reviewSign &&
			recipient.status === BUTTON_LABEL.PENDING
				? getAllSignNodes
				: [];
		return signingNodes.concat(assignNodes);
	}, [currentuserTabs, purpose, recipient.status, reviewSign, signingNodes]);

	// Hooks
	const { registerNewCredentials, authenticate, getRegistrations } =
		useWebAuthentication();
	const { patchDeviceInfo } = useUpdateDeviceInfo();

	const selectedNodeIndex = selectedNode.index;
	const { status: recipientStatus, action } = recipient;

	const { submitDocumentSigning, handleNewCreateSignId } =
		usePublishSignedDoc();
	const { nodeIterator } = useNodeIterator();

	const handleButtonClick = useCallback(
		(isNext: boolean) => {
			nodeIterator(isNext);
			(document.getElementById('sign-doc-next-btn') as HTMLElement).blur();
		},
		[nodeIterator]
	);

	const handleSuccess = useCallback(() => {
		patchDeviceInfo('signer');
	}, [patchDeviceInfo]);

	const handleAuthenticateUser = useCallback(async () => {
		console.log("handleAuthenticateUser function started");
	
		try {
			// Check environment and handle success if not staging
			if (!(env === ENV_STATE.STAGE || env === ENV_STATE.PREPROD)) {
				console.log("Environment is not staging or preprod. web authen by-passed");
				handleSuccess();
				return { success: true };
			}
	
			// Check if biometric authentication is available
			const isBiometricAvailable = await IsBiomentricSupported?.();
			if (
				window.self !== window.top ||
				!isBiometricAvailable
			) {
				console.warn('Web authentication is not supported in iframe or biometric is not available.');
				handleSuccess();
				return { success: true };
			}
	
			// Check if user is already registered
			const isAlreadyExist = getRegistrations().find(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(item: any) => item.email === recipient.email
			);
	
			if (!isAlreadyExist) {
				console.log("User is not already registered. Registering new credentials.");
				const registerPayload = {
					email: recipient.email || '123456****',
					id: recipient._id || '123456****',
					displayName: recipient.fullName || '123456****',
				};
				const resp = await registerNewCredentials(handleSuccess, registerPayload);
				return resp;
			}
	
			console.log("User is already registered. Authenticating...");
			const resp = await authenticate(handleSuccess, () => ({}));
			return resp;
		} catch (error) {
			console.error('An error occurred: completing webauthen', error);
			return;
		} finally {
			console.log("handleAuthenticateUser function finished");
		}
	}, [authenticate, env, getRegistrations, handleSuccess, recipient._id, recipient.email, recipient.fullName, registerNewCredentials]);
	

	/**
	 * here we are disabling the whole screen during finish api call
	 * */
	useEffect(() => {
		const pdfWrapper = document.querySelector(
			'.pdf-signer-container__image-wrapper'
		) as HTMLElement;
		if (pdfWrapper) {
			/**
			 * here we are disabling the pointer event based the loading status
			 * */
			pdfWrapper.style.pointerEvents = !isLoaded ? 'none' : 'unset';
		}
	}, [isLoaded]);

	/**
	 * this is check if all the required nodes are filled or not
	 * */
	const isAllNodesFilled = useMemo((): boolean => {
		if (!signingNodes.length) {
			return purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && reviewSign;
		}
		return signingNodes.every((node) => {
			const { metadata, value } = node;
			return !(
				(metadata?.required === true || metadata?.required === undefined) &&
				( node.questionType === 'matrixdropdown' ? false : (value === '' || value === null))
			);
		});
	}, [purpose, reviewSign, signingNodes]);

	const renderPrimaryBtnLabel = useMemo(() => {
		// Display loader if not loaded
		if (!isLoaded) {
			return <Loader dimension={14} className="loader-blue" type='circle' />;
		}

		// Only in case of 'MULTISIGNAGREEMENT', when there is no tabs in document and 'reviewSign' is true, then we return 'save and close' label
		if (
			!signingNodes.length &&
			purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT &&
			reviewSign
		) {
			return BUTTON_LABEL.SAVE_AND_CLOSE;
		}

		// Special case: MULTISIGNAGREEMENT, pending status, and review sign
		if (
			isAllNodesFilled &&
			purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT &&
			recipientStatus === BUTTON_LABEL.PENDING &&
			reviewSign
		) {
			return BUTTON_LABEL.SEND_FOR_REVIEW;
		}

		// Default cases based on node and index conditions
		return isAllNodesFilled
			? BUTTON_LABEL.FINISH
			: selectedNodeIndex > -1
			? BUTTON_LABEL.NEXT
			: BUTTON_LABEL.START;
	}, [
		isAllNodesFilled,
		isLoaded,
		purpose,
		recipientStatus,
		reviewSign,
		selectedNodeIndex,
		signingNodes
	]);

	const handleFinish = useCallback(async () => {
		console.log("handleFinish function started");
	
		const pdfWrapper = document.querySelector(
			zoomContentSelector
		) as HTMLElement;
		if (pdfWrapper) {
			pdfWrapper.style.removeProperty('transform');
			pdfWrapper.style.removeProperty('transform-origin');
		}
	
		try {
			console.log("Attempting to handle finish process...");
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const requiredNodes: any[] = [];
			if (!isIPhone) {
				console.log("Device is not iPhone. Authenticating user...");
				const resp = await handleAuthenticateUser();
				if (!resp) {
					console.log("User authentication failed. Setting isLoaded and facialBiometricStatus states.");
					setIsLoaded(true);
					setFacialBiometricStatus(false);
					return;
				}
			}
	
			console.log("Iterating over allSigningNodes...");
			allSigningNodes.forEach((node) => {
				console.log("Checking node:", node);
	
				if ((node.type === 'signature' || node.type === 'initial') &&
					purpose === 'multisignAgreement' &&
					reviewSign &&
					recipient.status === BUTTON_LABEL.PENDING) {
					console.log("Node meets required conditions. Adding to requiredNodes.");
					requiredNodes.push(node);
				}
	
				if (node.type === 'checkbox') {
					if (node.value === '') {
						console.log("Checkbox value is empty. Adding modified node to requiredNodes.");
						requiredNodes.push({ ...node, value: false });
					} else {
						console.log("Checkbox value is not empty. Adding node to requiredNodes.");
						requiredNodes.push({ ...node });
					}
				} else {
					if (node.value !== '') {
						console.log("Node value is not empty. Adding node to requiredNodes.");
						requiredNodes.push({ ...node });
						return;
					}
				}
			});
	
			console.log("Generating fieldNodes...");
			const externalUserNewSignId =
				renderPrimaryBtnLabel !== BUTTON_LABEL.SEND_FOR_REVIEW &&
				recipient.externalUser &&
				(await handleNewCreateSignId(envelopeId));
	
			const getValues = (value: string, name: string) => {
				if (recipient.externalUser) {
					if (name === 'initial') {
						return externalUserNewSignId?.initialDetail?._id || '';
					}
					if (name === 'signature') {
						return externalUserNewSignId?.signDetail?._id || '';
					}
				}
				if (name === 'date') {
					return value;
				}
				return value.toString();
			};
	
			const fieldNodes = requiredNodes.map(({ _id, value, file, name }) => {
				console.log("Mapping required nodes to fieldNodes...");
	
				const data: { id: string; value: string; image?: string } = {
					id: _id,
					value: getValues(value, name),
				};
				if (file) {
					console.log("File detected. Formatting image...");
					data.image = formatBase64(file as string);
				}
				return data;
			});
	
			console.log("Constructing payload...");
			const payload = {
				fieldTabs: fieldNodes,
				currentRecipientId: recipient._id,
			};
	
			console.log("Submitting document signing with payload:", payload);
			submitDocumentSigning(envelopeId, payload);
	
			console.log("Resetting facialBiometricStatus and isLoaded states.");
			setFacialBiometricStatus(false);
			setIsLoaded(true);
		} catch (error) {
			console.error("An error occurred:", error);
			setIsLoaded(true);
			setFacialBiometricStatus(false);
		}
		console.log("handleFinish function finished");
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isIPhone, allSigningNodes, recipient, recipient, recipient, handleNewCreateSignId, envelopeId, submitDocumentSigning, setFacialBiometricStatus, handleAuthenticateUser, purpose, reviewSign, renderPrimaryBtnLabel]);

	useEffect(() => {
		async function FacilaFinalSumbit() {
			if (facialBiometricStatus) {
				setTimeout(() => {
					handleFinish();
				}, 1000);
			}
		}
		FacilaFinalSumbit();
	}, [facialBiometricStatus, handleFinish]);

	const handleCompleteSign = useCallback(async () => {
		if (!isLoaded) {
			return
		}
		setIsLoaded(false);
		setTimeout(() => {
			if (authUserAuthType === 'facial') {
				setFacialModal(true);
			} else {
				handleFinish();
			}
		}, 1000);
	}, [authUserAuthType, handleFinish, isLoaded, setFacialModal]);

	useEffect(() => {
		if (isAllNodesFilled) {
			resetSelectedNode();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAllNodesFilled]);

	const handleClose = useCallback(() => {
		window.location.href = `/view-success-screen`;
	}, []);

	const handleCompleteAppleLogin = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(resp: any) => {
			const { authorization } = resp;
			if (authorization) {
				handleCompleteSign();
			} else {
				setIsLoaded(true);
			}
		},
		[handleCompleteSign]
	);

	const disableFinishButton = useMemo(() => {
		return !processingDoc && !isLoaded
	},[isLoaded, processingDoc])

	const renderPrimaryButton = useMemo(() => {
		const isSignComplete = /complete/gi.test(recipientStatus);
		const isReceivesCopyUser = /^Receives a copy$/.test(action);
	
		if (isSignComplete || isReceivesCopyUser) {
			return (
				<Button
					label="Close"
					handleClick={handleClose}
					className="esign-button__filled esign-button__filled--primary esign-button__large finish-sign__btn"
				/>
			);
		}
	
		if (!isAllNodesFilled) {
			return (
				<Button
					id="sign-doc-next-btn"
					label={renderPrimaryBtnLabel}
					className="esign-button__filled esign-button__filled--primary esign-button__large"
					handleClick={() => handleButtonClick(true)}
					disabled={!processingDoc}
				/>
			);
		}
	
		const finishButton = (
			<Button
				id="sign-doc-next-btn"
				label={renderPrimaryBtnLabel}
				className="esign-button__filled esign-button__filled--primary esign-button__large"
				handleClick={handleCompleteSign}
				disabled={disableFinishButton}
			/>
		);
	
		if (isIPhone) {
			const redirectURI = window.location.origin;
			return (
				<AppleLogin
					clientId="com.satschel.stage.esign.client"
					redirectURI={redirectURI}
					usePopup={true}
					callback={handleCompleteAppleLogin}
					scope="email"
					responseMode="query"
					render={(renderProps) => (
						<Button
							label={renderPrimaryBtnLabel}
							handleClick={() => {
								setIsLoaded(false);
								renderProps.onClick();
							}}
							className="esign-button__filled esign-button__filled--primary esign-button__large"
						/>
					)}
				/>
			);
		}
	
		return finishButton;
	}, [recipientStatus, action, isAllNodesFilled, renderPrimaryBtnLabel, handleCompleteSign, disableFinishButton, isIPhone, handleClose, processingDoc, handleButtonClick, handleCompleteAppleLogin]);
	

	return (
		<div className="signing-screen__footer">
			<>{renderPrimaryButton}</>
		</div>
	);
};
