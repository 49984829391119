import { FC, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import { IOption, ReactDropdown } from "@storybook";
import {
  IFieldNode,
  NodeConfigSetting,
  useGetConfiguredNode,
  useSetConfigFieldNode,
  VALIDATION_FILED_NAME,
  ValidationField,
} from "views";

interface IToggleComponent {
  label: string;
  options: IOption[];
  defaultValue: IOption;
  name: string;
  validationFields?: ValidationField,
  selectedValidationType?: string,
  shouldDefaultSelection?: boolean
}

export const DropdownComponent: FC<IToggleComponent> = ({
  label = "Text",
  options,
  defaultValue,
  name,
  validationFields,
  selectedValidationType,
  shouldDefaultSelection
}) => {
  const { handleConfigureNode } = useSetConfigFieldNode();
  const { configuredNode } = useGetConfiguredNode();
  const [height, setHeight] = useState(0);
  const [validationField, setValidationField] = useState<IFieldNode>();


  const myRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (myRef.current != null) {
    setHeight(myRef.current.offsetTop);
    }
  }, []);



  const setValidationCallback = useCallback((value: string | undefined | null) => {
    if(value && validationFields) {
      setValidationField(validationFields[value]);
    } else {
      setValidationField(undefined)
    }
  },[validationFields]);

  useEffect(() => {
    if((name === VALIDATION_FILED_NAME  || name === 'formate') && shouldDefaultSelection){
      setTimeout(() =>{
        handleConfigureNode(name, defaultValue);
      },0);
    }
  }, [name, defaultValue, shouldDefaultSelection])

  useEffect(() => {
      setValidationCallback(selectedValidationType)
  }, [selectedValidationType, validationFields])

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (selected: any) => {
      setValidationCallback(selected.value)
      handleConfigureNode(name, selected);
    },
    [handleConfigureNode, name]
  );

  const value = configuredNode(name) ?? defaultValue;
  const optionStyle = {
    height: 32,
    minHeight: 32,
    paddingTop: 6,
    fontSize: 14,
    "&:hover": { background: "var(--color-hover-light-100)" },
  };

  const controlStyle = {
    minHeight: 32,
    height: 32,
    borderRadius: 4,
    backgroundColor: "#fff",
    "&:hover": { borderColor: "var(--color-esign-primary-light)", cursor: "pointer" },
    fontWeight: 500,
    fontSize: 14,
  };

  const DropDownLabel = useMemo(() => label === 'Formate' ? 'Format' : label,[label])

  return (
    <>
      <div className="font-family-dropdown">
        <div className="font-family-dropdown__label" ref={myRef}>{DropDownLabel}</div>
        <ReactDropdown
          id="my-select"
          options={options as IOption[]}
          menuPlacement={height <= 12 || height <= 134 ? "bottom" : "top"}  
          optionsStyle={optionStyle}
          menuStyle={{maxHeight: 160}}        
          menuListStyle={{maxHeight: 160, overflow: "auto"}}
          valueContainerStyle={{marginTop: -3}} 
          indicatorsContainerStyle ={{marginTop: -3}}       
          controlStyle={controlStyle}
          handleChangeSelect={handleChange}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
      {validationField && <NodeConfigSetting field={validationField} />}
    </>
  );
};
