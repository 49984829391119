import {
	CSSProperties,
	FC,
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
} from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Collapsible } from '@storybook';
import { GetQueryParams, capitalize, formatDate } from 'utils';
import {
	CommonFields,
	ConfigTabMenuState,
	DROP_ITEM_SOURCE,
	DropItemsState,
	IDropedItems,
	IFieldNodeType,
	IMetadata,
	IRecipientUser,
	NodeConfigSetting,
	RecipientsState,
	SelectRecipientDropDown,
	SelectedConfigNode,
	currentNodePosition,
	templateTypeState,
	useSetConfigDoc,
} from 'views';
import { EnvelopePurposeState, webComponentEnvelopeIdState } from 'states';
import { useGetSelfSign } from 'views/self-sign/store';
import { ENVELOPE_PURPOSE, FIELD_TYPE } from 'constant';

import { IsConfigDrawerOpen, IsShowMessage } from '../store';

import CheckboxConfiguration from './checkbox-configuration-component/checkbox-configuration';
import RadiogroupConfiguration from './radiogroup-configuration-component/radiogroup-configuration';

interface IOption {
	label: string;
	value: string;
}

type INodesDimention = {
	[key in IFieldNodeType]: { height: string; width: string };
};

const nodesDefaultDimention: INodesDimention = {
	checkbox: { height: '16px', width: '16px' },
	radiogroup: { height: '16px', width: '16px' },
	date: { height: '20px', width: '140px' },
	email: { height: '20px', width: '140px' },
	hyperlink: { height: '40px', width: '40px' },
	initial: { height: '40px', width: '80px' },
	name: { height: '20px', width: '140px' },
	signature: { height: '40px', width: '160px' },
	text: { height: '20px', width: '140px' },
	title: { height: '20px', width: '140px' },
};

const selfSignConfigFieldNodes: { [key: string]: boolean } = {
	tooltip: false,
	editText: false,
	required: false,
	readOnly: false,
};

interface IConfigNodeBody {
	dropContainerDimension: MutableRefObject<{ width: number; height: number }>;
}

export const ConfigNodeBody: FC<IConfigNodeBody> = ({
	dropContainerDimension,
}) => {
	const [dropItemStates, setDropItems] = useRecoilState(DropItemsState);
	const [configItems, setConfigItems] = useRecoilState(SelectedConfigNode);
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const { handleDeleteNode } = useSetConfigDoc();
	const recipients = useRecoilValue(RecipientsState);
	const setShowMessage = useSetRecoilState(IsShowMessage);
	const setCurrentNodePosition = useSetRecoilState(currentNodePosition);
	const { selectedFieldsNodes = [], metaKeys, menuTabs } =
		useRecoilValue(ConfigTabMenuState);
	const isEnvelopeId = !!GetQueryParams('envelopeId');
	const envelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const templateType = useRecoilValue(templateTypeState);
	const purpose = useRecoilValue(EnvelopePurposeState);

	const { getTheDefaultValue } = useGetSelfSign();
	const renderConfigFields = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const selfSignFieldOptions = selectedFieldsNodes.filter((node: any) => {
			return selfSignConfigFieldNodes[node.key as string] ?? true;
		});
		const configNodeOptions =
			purpose !== 'selfsign' ? selectedFieldsNodes : selfSignFieldOptions;

		return configNodeOptions.length ? (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			configNodeOptions.map((field: any, index: number) => (
				<NodeConfigSetting field={field} key={index} />
			))
		) : (
			<div className="config-items__empty">No configuration available</div>
		);
	}, [purpose, selectedFieldsNodes, configItems]);

	const handleAddMetaKey = useCallback(() => {
		// Retrieve the dimensions of the drop container
		const { width: containerWidth, height: containerHeight } =
			dropContainerDimension.current ?? {};
		setCurrentNodePosition({ containerWidth, containerHeight });

		// Check if necessary data exists to proceed
		if (configItems && containerHeight && containerWidth) {
			setDropItems((prev: IDropedItems[]) => {
				// Create a deep copy of the previous state for modification
				const prevState = JSON.parse(JSON.stringify(prev));

				// Find the index of the item to be updated in the state
				const foundIndex = prevState.findIndex(
					(item: IDropedItems) => item.id === configItems.id
				);

				// Check if the item is found
				if (foundIndex > -1) {
					// Extract the found item from the previous state
					const foundItem: IDropedItems = prevState[foundIndex];

					// Check if the metadata for the found item is empty
					const metadataLength = Object.keys(foundItem.metadata);
					if (!metadataLength.length) {
						// Extract default width and height based on the field type of the item
						const { width, height } =
							nodesDefaultDimention[foundItem.fieldType];
						const { position } = foundItem;

						// Parse width and height for calculations
						const nodeWidth = parseInt(`${width}`.replace('px', ''));
						const nodeHeight = parseInt(`${height}`.replace('px', ''));

						// Calculate new positions based on container size
						const xRight =
							(position.xLeft * containerWidth + nodeWidth) / containerWidth;
						const yBottom =
							(position.yTop * containerHeight + nodeHeight) / containerHeight;

						// Update metadata for the found item
						foundItem.metadata = {
							...metaKeys,
							editText: foundItem.label,
							width,
							height,
						} as IMetadata;
						// Update the new position for the found item
						foundItem.position = {
							...position,
							xRight,
							yBottom,
						};
						foundItem.containerHeight = containerHeight;
						foundItem.containerWidth = containerWidth;

						if (purpose === ENVELOPE_PURPOSE.SELFSIGN) {
							let value = getTheDefaultValue[foundItem.fieldType];
							if (
								foundItem.fieldType === FIELD_TYPE.DATE &&
								foundItem.fieldType === "date"
							) {
								value = formatDate(new Date(), "mm/dd/yyyy");
							}
							foundItem.value = value;
						}
						// Replace the updated item in the previous state
						prevState.splice(foundIndex, 1, foundItem);
					}
				}

				// Return the updated state
				return prevState;
			});
		}
	}, [
		dropContainerDimension,
		setCurrentNodePosition,
		configItems,
		setDropItems,
		metaKeys,
		purpose,
		getTheDefaultValue,
	]);

	useEffect(() => {
		if (selectedFieldsNodes?.length) {
			handleAddMetaKey();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFieldsNodes]);

	const handleDelete = useCallback((): void => {
		if (configItems) {
			handleDeleteNode(configItems.id);
			setShowMessage(false);
			setIsDrawerOpen(false);
		}
	}, [configItems, handleDeleteNode, setIsDrawerOpen, setShowMessage]);

	const handleCloseDrawer = useCallback(() => {
		setShowMessage(false);
		setIsDrawerOpen(false);
	}, [setIsDrawerOpen, setShowMessage]);

	const handleSelectRecipient = useCallback(
		(data: IOption) => {
			const { id: dropItemId } = configItems || {};
			setDropItems((previousValue: IDropedItems[]) =>
				previousValue.map((item: IDropedItems) =>
					item.id === dropItemId ? { ...item, recipient: data.value } : item
				)
			);
			if (configItems) {
				const newItem = { ...configItems, recipient: data?.value };
				setConfigItems(newItem);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[configItems]
	);

	const controlStyle: CSSProperties = {
		minHeight: 36,
		maxHeight: 36,
		borderRadius: 4,
		fontSize: 14,
	};

	const menuStyle: CSSProperties = {
		borderRadius: 4,
	};

	const selectedRecipientValue: IOption = useMemo(() => {
		// If SelectedConfigNode is common then return only common fields
		if (configItems?.source==='common') {
			return {
				label: CommonFields,
				value: '',
				color: '#6c707a',
			};
		}

		const { recipient } = configItems || {};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const currentRecipient = recipients.find(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(item: any) => item.id === recipient
		);
		const { fullName, id, color, title } =
			currentRecipient ?? ({} as IRecipientUser);
		return {
			label: fullName ?? title ?? '',
			value: id ?? '',
			color: color,
		};
	}, [configItems, recipients]);

	const {selectedDropitem, checkboxOptionChoices} = useMemo(() => {
		const foundIndex = dropItemStates.findIndex(
			(item: IDropedItems) => item.id === configItems?.id
		);
		const dropItem = dropItemStates[foundIndex];
		const questionaireItem = menuTabs.questionnaire?.items.find((item) => item.key === dropItem?.key);
		return {  selectedDropitem: dropItemStates[foundIndex], checkboxOptionChoices: questionaireItem?.choices};
	}, [configItems?.id, dropItemStates, menuTabs.questionnaire]);

	return (
		<div className="config-items">
			<div className="config-items__header">
				<div className="config-items__header-back" onClick={handleCloseDrawer}>
					<i className="ri-arrow-left-line" />
				</div>
				<span>{`${capitalize(
					configItems ? configItems?.fieldType : ''
				)} Editor`}</span>
			</div>
			<div className="config-items__wrap">
				<div className="config-items__body" id="config-component-body">
					{((isEnvelopeId || envelopeId ) || templateType === 'overlay') &&
						purpose !== 'selfsign' && (
							<Collapsible
								header="Recipients"
							>
								<SelectRecipientDropDown
									handleSelectRecipient={handleSelectRecipient}
									selectedRecipientValue={selectedRecipientValue}
									menuStyle={menuStyle}
									controlStyle={controlStyle}
									defaultValue={selectedRecipientValue}
									isDisable={configItems?.source === 'common'}
								/>
							</Collapsible>
						)
					}
					{
						selectedDropitem?.fieldType === 'radiogroup'
						&& <RadiogroupConfiguration 
							dropitems={dropItemStates} 
							selectedDropItem={selectedDropitem} 
							radiogroupOptionChoices={checkboxOptionChoices}
						/>

					}
					<Collapsible
						header="Formatting"
					>
						{renderConfigFields}
					</Collapsible>
					{
						(selectedDropitem?.source === DROP_ITEM_SOURCE.questionaire 
						&& checkboxOptionChoices && checkboxOptionChoices?.length > 0 ) &&
						<Collapsible
							header="Configurations"	
						>
							<CheckboxConfiguration 
								selectedDropitem={selectedDropitem} 
								checkboxOptionChoices={checkboxOptionChoices} 
							/>
						</Collapsible>
					}
				</div>
			</div>
			<div className="config-items__footer">
				<Button
					label={'Delete'}
					className="esign-button__filled esign-button__filled--danger esign-button__block esign-button__large"
					handleClick={handleDelete}
					prefixIcon={'ri-delete-bin-6-line'}
				/>
			</div>
		</div>
	);
};
