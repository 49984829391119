/* eslint-disable @typescript-eslint/no-explicit-any */
import { REACT_APP_LIQUIDITY_BUSINESSID } from 'envs';
import { useRecoilValue } from 'recoil';
import { EnvState } from 'states';
import { SignDocumentState } from 'views';

export const useTrackEvents = () => {
	const envHost = useRecoilValue(EnvState);
	const signDocState = useRecoilValue(SignDocumentState);
	const { businessId = '' } = signDocState?.data ?? {};

	const trackEvents = (eventName: string, properties: any) => {
		let host;
		try {
			host = (window as any).top.location.host || (window as any).location.host;
		} catch (err) {
			return;
		}

		const isProd = /liquidity.io/gi.test(host);
		const isFromLiquidity =
			REACT_APP_LIQUIDITY_BUSINESSID !== businessId || !isProd;

		if (isFromLiquidity) return;
		if (!(window as any)?.gtag) {
			return;
		}
		try {
			(window as any).gtag('event', `${envHost}_${eventName}_sim`, {
				...properties,
			});

			if ((window as any)?.analytics) {
				(window as any).analytics.track(`${envHost}_${eventName}_sim`, {
					...properties,
				});
			}
		} catch (error) {
			return;
		}
	};

	return { trackEvents };
};
