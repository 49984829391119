import type { IFocusEvent, IInputChange, ITextAreaChange } from 'types';

import {
	CSSProperties,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';

import { Tooltip } from '@storybook';
import { DeviceType, useDeviceType } from 'views';

import { IMetadata, ITab } from '../store';

interface IProps {
	type: 'text' | 'email';
	name: string;
	metadata: IMetadata;
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleChangeText: (e: ITextAreaChange | IFocusEvent | string) => void;
	nodeId: string;
	value?: string;
	isSelectedClass?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSelectedNode?: (event: any) => void;
	node: ITab;
	styles?: CSSProperties;
}

export const TextInputNode: FC<IProps> = ({
	isSelectedClass,
	handleChangeText,
	label,
	metadata,
	nodeId,
	handleSelectedNode,
	value,
	node,
	styles,
}) => {
	const { deviceType } = useDeviceType();
	const ref = useRef<HTMLInputElement>(null);
	useEffect(() => {
		const textInputNode = document.getElementById(
			`${nodeId}-text-input`
		) as HTMLElement;
		if (textInputNode) {
			textInputNode.style.fontSize = `${metadata.fontSize}`;
			textInputNode.style.fontFamily = `${metadata.fontFamily}`;
		}
		if (isSelectedClass) {
			textInputNode?.focus();
			textInputNode.style.border = 'none !important';
			textInputNode.style.color = `${metadata.fontColor}`;
		}
	}, [
		isSelectedClass,
		metadata.fontColor,
		metadata.fontFamily,
		metadata.fontSize,
		nodeId,
		value,
	]);

	
	const handleInputChange = useCallback(
		(event: IInputChange) => {
			const { value } = event.target ;
				handleChangeText(value);
		},
		[handleChangeText]
	);

	const {width, maxWidth} = useMemo(() => {
		const viewWidth = 590;
		const left = node.position.xLeft;
		const maxWidth = viewWidth - (viewWidth * left);
	
		let width = Number(metadata.width);
		if (ref.current) {
			const {scrollWidth, offsetWidth} = ref.current;
			// to reset the width
			if (scrollWidth < offsetWidth) {
				width = Number(metadata.width)
			} else {
				width = scrollWidth
			}
		}
		return {
			maxWidth,
			width
		}
		
	}, [metadata.width, node])

	return (
		<>
			<input
				ref={ref}
				type="text"
				onChange={handleInputChange}
				value={value}
				placeholder={metadata.editText || label}
				id={`${nodeId}-text-input`}
				onFocus={handleSelectedNode}
				style={{
					width: width + "px",
					...styles,
					minWidth: metadata.width,
					maxWidth,
				}}
				maxLength={Number(metadata.characterLimit) || 100}
				className={`signing-node__text-input-node ${isSelectedClass}`}
			/>
			{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
				<Tooltip
					id={`${nodeId}-text-input`}
					variant="info"
					content={`${
						node.metadata.required === false
							? 'Optional field'
							: 'Required field'
					} ${label}`}
					className="pdf-signer-container__tooltip pdf-signer-container__tooltip-length"
				/>
			)}
		</>
	);
};

// need not to be removed we may need content editable so for reference lets keep this

// import type { IFocusEvent, ITextAreaChange } from 'types';

// import {
// 	CSSProperties,
// 	FC,
// 	useCallback,
// 	useEffect,
// 	useMemo,
// 	useRef,
// } from 'react';

// import { Tooltip } from '@storybook';
// import { DeviceType, useDeviceType } from 'views';

// import { IMetadata, ITab } from '../store';

// interface IProps {
// 	type: 'text' | 'email';
// 	name: string;
// 	metadata: IMetadata;
// 	label: string;
// 	handleChangeText: (e: ITextAreaChange | IFocusEvent | string) => void;
// 	nodeId: string;
// 	value?: string;
// 	isSelectedClass?: string;
// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
// 	handleSelectedNode?: (event: any) => void;
// 	node: ITab;
// 	styles?: CSSProperties;
// }

// export const TextInputNode: FC<IProps> = ({
// 	isSelectedClass,
// 	handleChangeText,
// 	label,
// 	metadata,
// 	nodeId,
// 	handleSelectedNode,
// 	value,
// 	node,
// 	styles,
// }) => {
// 	const contentEditableRef = useRef<HTMLDivElement>(null);
// 	const { deviceType } = useDeviceType();

// 	useEffect(() => {
// 		if (contentEditableRef.current) {
// 			contentEditableRef.current.textContent = value ?? ""
// 		}
// 	}, [value]);

// 	useEffect(() => {
// 		const textInputNode = document.getElementById(
// 			`${nodeId}-text-input`
// 		) as HTMLElement;
// 		if (textInputNode) {
// 			textInputNode.style.fontSize = `${metadata.fontSize}`;
// 			textInputNode.style.fontFamily = `${metadata.fontFamily}`;
// 		}
// 		if (isSelectedClass) {
// 			textInputNode?.focus();
// 			textInputNode.style.border = 'none !important';
// 			textInputNode.style.color = `${metadata.fontColor}`;
// 		}
// 	}, [
// 		isSelectedClass,
// 		metadata.fontColor,
// 		metadata.fontFamily,
// 		metadata.fontSize,
// 		nodeId,
// 		value,
// 	]);

// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
// 	const handleInputChange = useCallback((event: any) => {
// 		const { textContent } = event.target as HTMLDivElement;
// 		if (textContent) {
// 			handleChangeText(textContent);
// 		}
// 	}, [handleChangeText]);

// 	const getPlaceholder = useMemo((): string => {
// 		if (value) {
// 			return '';
// 		}
// 		return metadata.editText || label;
// 	}, [label, metadata, value]);

// 	return (
// 		<>
// 			<div
// 				ref={contentEditableRef}
// 				role="textbox"
// 				contentEditable
// 				onInput={handleInputChange}
// 				onFocus={handleSelectedNode}
// 				id={`${nodeId}-text-input`}
// 				style={{
// 					...styles,
// 					minWidth: metadata.width,
// 					maxWidth: metadata.width,
// 				}}
// 				suppressContentEditableWarning={true}
// 				placeholder={getPlaceholder}
// 				className={`signing-node__text-input-node ${isSelectedClass}`}
// 			>
// 			</div>
// 			{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
// 				<Tooltip
// 					id={`${nodeId}-text-input`}
// 					variant="info"
// 					content={`${
// 						node.metadata.required === false
// 							? 'Optional field'
// 							: 'Required field'
// 					} ${label}`}
// 					className="pdf-signer-container__tooltip"
// 				/>
// 			)}
// 		</>
// 	);
// };
