// Import environment variables that contain Firebase configuration values
import {
	REACT_APP_FIREBASE_APIKEY,
	REACT_APP_FIREBASE_AUTHDOMAIN,
	REACT_APP_FIREBASE_DATABASEURL,
	REACT_APP_FIREBASE_PROJECTID,
	REACT_APP_FIREBASE_STORAGEBUCKET,
	REACT_APP_FIREBASE_MESSAGINGSENDERID,
	REACT_APP_FIREBASE_AAPID,
} from 'envs';
// Import Firebase modules needed to initialize the app and access the Realtime Database
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

/**
 * Custom hook to initialize Firebase and provide access to the Realtime Database.
 * 
 * This hook sets up the Firebase configuration, initializes the Firebase app,
 * and returns an instance of the Firebase Realtime Database.
 * 
 * @returns {Object} An object containing the Firebase Realtime Database instance.
 */
export const useFirebaseInit = () => {
	// Firebase configuration object, containing the required keys from environment variables
	const firebaseConfig = {
		apiKey: REACT_APP_FIREBASE_APIKEY, // API key for authenticating requests
		authDomain: REACT_APP_FIREBASE_AUTHDOMAIN, // Domain for Firebase Authentication
		databaseURL: REACT_APP_FIREBASE_DATABASEURL, // URL of the Firebase Realtime Database
		projectId: REACT_APP_FIREBASE_PROJECTID, // Firebase project ID
		storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET, // Storage bucket for Firebase Storage
		messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID, // Sender ID for Firebase Cloud Messaging
		appId: REACT_APP_FIREBASE_AAPID, // App ID for Firebase configuration
	};

	// Initialize the Firebase app using the configuration object
	const app = initializeApp(firebaseConfig);

	// Get an instance of the Firebase Realtime Database from the initialized app
	const database = getDatabase(app);

	// Return the database instance so it can be used elsewhere in the application
	return { database };
};
