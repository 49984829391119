import React from 'react';

import { Input } from '@storybook';
import { IDropedItems } from 'views/editor-dashboard';



interface RadioGroupProps {
    filterSameRadiobuttons: IDropedItems[];
    selectedDropItem: IDropedItems | undefined;
    handleOptionChange: (item: IDropedItems) => void;
    onInputFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGrouCustomOptions: React.FC<RadioGroupProps> = ({
    filterSameRadiobuttons,
    selectedDropItem,
    handleOptionChange,
    onInputFieldChange,
}) => {
    return (
        <div>
            {filterSameRadiobuttons.map((item, index) => (
                <div key={item.id} className="radio-group-item">
                    <label className="option">
                        <input
                            type="radio"
                            name={item.name + 'sdf'}
                            value={item.metadata.radiogroupValue}
                            checked={item.id === selectedDropItem?.id}
                            onChange={() => handleOptionChange(item)}
                        />
                        <span>Option {index + 1}</span>
                    </label>
                    <div className="config-text-input">
                        <Input
                            label={''}
                            inputType={"text"}
                            required = {true}
                            placeholder={'Enter option value'}
                            handleChange={onInputFieldChange}
                            value={item.metadata.radiogroupValue}
                            disabled={item.id !== selectedDropItem?.id}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
