import { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import { FadeHexColor, isSafari } from 'utils';
import {
	AllowedDraggableNodes,
	DragTypes,
	DraggableBox,
	HoveredMenuButtons,
	ITabMenu,
	MenuItemIcons,
	QuestionTypeNames,
	useGetRecipient,
} from 'views';
import { Tooltip } from '@storybook';
import { v4 } from 'uuid';

interface IMenuItem {
	item: ITabMenu;
	showHoveredItem?: boolean;
}

export const MenuItem: FC<IMenuItem> = ({ item, showHoveredItem }) => {
	const [isHovered, setIsHovered] = useState(false);
	const { activeRecipientColor } = useGetRecipient();

	const disabledNodeItems =
		item.questionType && !(item.questionType in AllowedDraggableNodes);

	const menuTabClassName = classNames('menu-tab__box', {
		'menu-tab__box--disabled': disabledNodeItems,
		'menu-tab__box-safari' : isSafari() // Adds this class if the browser is Safari
	});

	const idOfDragable = useMemo(() => {
		return `${item.label}-${item.key}-${item._id}-${item.questionType}-${v4()}`.replace(/[^a-zA-Z0-9]/g, '-');
	},[item])

	return (
		<DraggableBox
			styles={{
				background: FadeHexColor(`${activeRecipientColor}`, 0.12),
				borderColor: 'rgba(0, 0, 0, 0.12)',
				borderLeft: `4px solid ${activeRecipientColor}`,
			}}
			hideSourceOnDrag={false}
			data={item}
			type={DragTypes.SideBarItem}
			handleMouseEnter={() => setIsHovered(true)}
			handleMouseLeave={() => setIsHovered(false)}
			className="menu-tab__box-container"
			showEmptyPlaceholder={false} // this as true for changing drag preview
		>
			<>
				<div
					className={menuTabClassName}
					id={idOfDragable}
				>
					<div className='menu-tab__box-wrapper'>
						<i
							className={
								MenuItemIcons[
									(item.questionType ??
										item.fieldType) as keyof typeof MenuItemIcons
								] ?? 'ri-align-right'
							}
						/>
						{item.label}{' '}
					</div>
					{item.isNew && showHoveredItem && (
						<HoveredMenuButtons item={item} hovered={isHovered} />
					)}
				</div>
				{disabledNodeItems && (
					<Tooltip
						id={idOfDragable}
						content={`${
							QuestionTypeNames?.[
								item?.questionType as keyof typeof QuestionTypeNames
							] ?? item?.questionType
						} nodes are not supported yet`}
					/>
				)}
			</>
		</DraggableBox>
	);
};
