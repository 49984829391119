import { atom } from "recoil";

export interface ICheckboxAppearance { [key: string]: string }
export interface IRadioAppearance { [key: string]: string }

export const IsConfigDrawerOpen = atom<boolean>({
    key: "is-config-drawer-open",
    default: false,
})

export const IsShowMessage = atom<boolean>({
    key: "is-show-message",
    default: false,
})

export const currentCheckboxAppearanceState = atom<ICheckboxAppearance>({
    key: "current-checkbox-appearance-state",
    default: {},
})

export const currentRadioAppearanceState = atom<IRadioAppearance>({
    key: "current-radio-appearance-state",
    default: {},
})
