import { atom } from 'recoil';

export interface HeaderFont {
	family: string;
	category: string;
	_id: string;
}

export interface BodyFont {
	family: string;
	category: string;
	_id: string;
}

export interface Settings {
	brandColor: string;
	headerFont: HeaderFont;
	bodyFont: BodyFont;
	_id: string;
}

export interface IOrganization {
	name: string;
	logo: string;
	settings: Settings;
	whitelabel: boolean;
	loader: string;
	loaderWhitelabel: boolean;
}

export const organizationDetailState = atom<IOrganization>({
	key: 'organization-detail-state',
	default: {
		name: '',
		logo: '',
		settings: {
			brandColor: '',
			headerFont: {
				family: '',
				category: '',
				_id: '',
			},
			bodyFont: {
				family: '',
				category: '',
				_id: '',
			},
			_id: '',
		},
		loader: '',
		loaderWhitelabel: false,
		whitelabel: false,
	},
});
