import { useCallback, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios, { AxiosError } from 'axios';
import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	ConfigDocumentState,
	configDocTitleState,
	useSetConfigDoc,
} from 'views/editor-dashboard';
import {
	IsEnableMerchantFlow,
	RecipientLocalState,
	RecipientsState,
	getColor,
	isSigningOrderEnabledState,
} from 'views/reciepient-modal';
import { v4 } from 'uuid';
import { IRecipient, newRecipient } from 'views';
import { EnvState, organizationDetailState } from 'states';
import { useFirebaseInit } from 'hooks/firebase';
import { onValue, ref } from 'firebase/database';
import { base64ToBinary } from 'utils';

import { Memory } from './memory';

import {
	IEnvelopeListDetails,
	ITemplateType,
	IUploadDoc,
	IUploadDocDetails,
	TemplateSourceState,
	UploadedEnvelopeDocsState,
	templateTypeState,
} from '.';

const { FILE_UPLOADED, UPLOAD_FAILED } = MESSAGE;

export const useUploadMultiDoc = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const [isLoaded, setIsLoaded] = useState(true);
	const envHost = useRecoilValue(EnvState);

	// Tracks the ID of the uploaded document
	const [uploadedDocId, setUploadedDocId] = useState('');

	const { successNotification, errorNotification } = useNotification();
	const { post, remove, patch } = useNetwork();

	// Access the initialized Firebase database instance
	const { database } = useFirebaseInit();

	// Listen firestore trigger events if any session perform write operations
	useEffect(() => {
		// Monitor changes to the uploaded document in the database when uploadedDocId is provided
		if (uploadedDocId) {
			// Create a reference to the document's data in the database
			const dataRef = ref(database, uploadedDocId);

			// Set up a real-time listener for changes to the document's data
			const unsubscribe = onValue(dataRef, (snapshot) => {
				const data = snapshot.val();

				// eslint-disable-next-line no-console
				console.info({ uploadedFile: data });

				// Ignore updates if the timestamp matches the previously stored timestamp
				if (data?.timeStamp === Memory.getTimeStamp()) {
					return;
				}

				// Update the stored timestamp with the new one
				Memory.setTimeStamp(data?.timeStamp);

				// If the document's ID matches the uploadedDocId, process the document data
				if (data?._id === uploadedDocId) {
					const { _id: id, name: documentName = '' , error } = data ?? {};

					if (documentName && !error) {
						// Construct the new document object with required details
						const uploadDoc: IEnvelopeListDetails = {
							id,
							documentName,
							tabsCount: '0', // Default tabs count
							pagesCount: data?.pages?.length ?? 0, // Count of pages in the document
							tabs: [], // Tabs will be empty initially
							newUpload: true, // Mark as a newly uploaded document
						};

						// Update the envelope documents state with the new document
						setEnvelopeDocs((prev) => ({
							...prev,
							isLoading: false,
							data: [...prev.data, uploadDoc],
						}));

						// Reset the uploaded document ID to an empty string
						setUploadedDocId('');

						// Notify the user of a successful file upload
						successNotification(FILE_UPLOADED);
					}
					else {
						// Reset the uploaded document ID to an empty string
						setUploadedDocId('');
						
						// Notify the user of a unsuccessful file upload
						errorNotification(error ?? UPLOAD_FAILED)

						// Update the envelope documents state with the new document
						setEnvelopeDocs((prev) => ({
							...prev,
							isLoading: false,
						}));
					}
				}
			});
			// Cleanup logic
			return () => {
				unsubscribe(); // Detach the Firestore listener
			};
		}
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadedDocId, database]);

	// Refactored function to get the pre-signed upload URL and handle file upload
	const gerUploadUrl = useCallback(
		async (file: IUploadDocDetails) => {
			// Send the POST request with the file name
			const response = await post(API_URL.PRE_SIGN_UPLOAD, {
				filename: file?.originalname || '',
			});

			// Check if the response contains valid data and set the document ID
			const documentId = response?.apiData?.data?.documentId || '';
			setUploadedDocId(documentId);

			// Return the relevant data or null if not available
			return response?.apiData ?? null;
		},
		[post]
	);

	const uploadOnGcp = useCallback(
		async (url: string, file: IUploadDocDetails) => {
			try {
				// Convert Base64 file data to binary (Uint8Array)
				const fileBinary = base64ToBinary(file.base64File);

				// Send a PUT request to the provided pre-signed URL with the binary file
				const response = await axios.put(url, fileBinary, {
					headers: {
						'Content-Type': file?.extension || '', // Ensure the correct MIME type for the file
						'x-goog-meta-presignedupload': true,
						'x-goog-meta-apiDomain': envHost === 'preprod' ? 'pp' : envHost,
					},
				});

				// Check for non-successful responses
				if (response.status !== 200) {
					throw new Error('Failed to upload the file to GCP.');
				}
			} catch (error) {
				// Reset loading state in case of an error
				setEnvelopeDocs((prev) => ({
					...prev,
					isLoading: false,
				}));

				// Display a user-friendly error notification
				errorNotification(
					(error as AxiosError)?.message ??
					'An error occurred during file upload.'
				);
			}
		},
		[envHost, errorNotification, setEnvelopeDocs]
	);

	const uploadDocuments = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: IUploadDoc): Promise<any> => {
			const resp = await post(`${API_URL.UPLOAD}`, payload);
			return resp;
		},
		[post]
	);

	const uploadDocs = useCallback(
		async (payload: IUploadDoc): Promise<void> => {
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: true,
			}));
			const resp = await uploadDocuments(payload);
			const { apiData } = resp ?? {};
			if (apiData?.data) {
				const { data } = apiData.data;
				const { _id: id, name: documentName = '' } = data ?? {};
				const uploadDoc: IEnvelopeListDetails = {
					id,
					documentName,
					tabsCount: '0',
					pagesCount: data?.pages?.length ?? 0,
					tabs: [],
					newUpload: true,
				};
				setEnvelopeDocs((prev) => ({
					...prev,
					isLoading: false,
					data: [...prev.data, uploadDoc],
				}));
				successNotification(FILE_UPLOADED);
				return;
			}
			errorNotification(
				apiData.message === 'Encrypted Document'
					? apiData.message
					: UPLOAD_FAILED
			);
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: false,
			}));
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDocuments]
	);

	const swapDoc = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any, docId: string, index: number) => {
			setIsLoaded(false);
			const uploadResponse = await uploadDocuments(payload);
			const { apiData: uploadedDoc, response: uploadedDocResponse } =
				uploadResponse;
			if (uploadedDocResponse.status === 200) {
				const { _id } = uploadedDoc.data.data;
				const swapResp = await patch(`${API_URL.DOCUMENTS}/${docId}`, { _id });
				const { apiData, response } = swapResp;
				if (response?.status === 200) {
					const { name } = apiData.data;
					setEnvelopeDocs((prev) => {
						const prevState = structuredClone(prev.data);
						const foundDoc = prevState[index];
						if (foundDoc) {
							prevState.splice(index, 1, {
								...foundDoc,
								documentName: name ?? '',
							});
						}
						return { isLoading: prev.isLoading, data: prevState };
					});
					successNotification('Document swapped successfully.');
				} else {
					errorNotification(
						apiData.message ?? 'Failed to swap document. try again later.'
					);
				}
			} else {
				errorNotification(
					uploadedDocResponse?.message ??
					'Failed to swap document. try again later'
				);
			}
			setIsLoaded(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDocuments]
	);

	const deleteDoc = useCallback(
		async (id: string): Promise<void> => {
			await remove(`${API_URL.DOCUMENTS}/${id}`);
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[remove, successNotification]
	);

	const uploadDoc = useCallback(
		async (payload: IUploadDoc) => {
			// Set loading state to true when starting the document upload process
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: true,
			}));

			// Retrieve the pre-signed upload URL from the backend
			const uploadResp = await gerUploadUrl(payload.data);

			// Destructure the response to get the upload data and error message
			const { data: uploadData, message } = uploadResp ?? {};

			// If uploadUrl is available, proceed with uploading the document to GCP
			if (uploadData?.uploadUrl) {
				await uploadOnGcp(uploadData.uploadUrl, payload.data);
			} else {
				// If no upload URL is found, reset the loading state and show error message
				setEnvelopeDocs((prev) => ({
					...prev,
					isLoading: false,
				}));
				errorNotification(message ?? 'Something went wrong.');
			}
		},
		[errorNotification, gerUploadUrl, setEnvelopeDocs, uploadOnGcp]
	);

	return {
		uploadDoc,
		uploadDocs,
		deleteDoc,
		swapDoc,
		isLoaded,
	};
};

export const usePrepareOverlay = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const { get, patch } = useNetwork({ updateState: false });
	const setConfigDocTitle = useSetRecoilState(configDocTitleState);
	const setRecipients = useSetRecoilState(RecipientsState);
	const setDocuments = useSetRecoilState(ConfigDocumentState);
	const setTemplateType = useSetRecoilState(templateTypeState);
	const setOrganization = useSetRecoilState(organizationDetailState);
	const setLocalRecipients = useSetRecoilState(RecipientLocalState);
	const setTemplateSource = useSetRecoilState(TemplateSourceState);
	const { multiDocHandler } = useSetConfigDoc();
	const setIsMerchantFlow = useSetRecoilState(IsEnableMerchantFlow);
	const setSignOrder = useSetRecoilState(isSigningOrderEnabledState);
	const { errorNotification } = useNotification();

	const fetchOverlayTemplate = useCallback(
		async (templateId: string) => {
			setDocuments((prev) => ({
				...prev,
				isLoaded: false,
				isLoading: true,
			}));
			// fetch overlay template
			const resp = await get(`${API_URL.TEMPLATE}/${templateId}`);
			const { apiData, response } = resp;
			if (response?.status === 200) {
				const {
					recipients,
					documents,
					name = 'Untitled',
					createdAt,
					type,
					source,
					whiteLabelInfo,
					reviewSign,
					signOrder,
				} = apiData.data;
				setConfigDocTitle({ name, createdAt });
				const allRecipients = recipients.map((recipient: IRecipient) => ({
					...recipient,
					color: recipient.colorCode,
				}));
				setIsMerchantFlow(reviewSign);
				setSignOrder(signOrder);
				setTemplateSource(source);
				setRecipients(allRecipients);
				const { multiDocDataArray, envelopeList } = multiDocHandler(documents);
				setDocuments((prev) => ({
					...prev,
					isLoaded: true,
					isLoading: false,
					data: [...multiDocDataArray],
				}));
				const firstEmptyForm = {
					...newRecipient,
					color: getColor(),
					id: 'temp' + v4(),
				};
				// set white label data
				if (whiteLabelInfo?.whitelabel) {
					setOrganization(whiteLabelInfo);
				}
				setTemplateType(type as ITemplateType);
				setEnvelopeDocs({ isLoading: false, data: envelopeList });
				setLocalRecipients(
					allRecipients.length ? allRecipients : [firstEmptyForm]
				);
				return;
			}
			setDocuments((prev) => ({
				...prev,
				isLoaded: true,
				isLoading: false,
			}));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[multiDocHandler]
	);

	const patchOverlaytemplate = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any, templateId: string) => {
			const resp = await patch(`${API_URL.TEMPLATE}/${templateId}`, payload);
			const { apiData, response } = resp;
			const { recipients } = apiData.data;
			const allRecipients = recipients.map((recipient: IRecipient) => ({
				...recipient,
				color: recipient?.colorCode,
				id: recipient?._id,
			}));
			setRecipients(allRecipients);
			setLocalRecipients(allRecipients);
			if (response?.status === 200) {
				return true;
			} else {
				errorNotification(
					apiData?.message ?? 'Failed to start template configuration'
				);
				return false;
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { fetchOverlayTemplate, patchOverlaytemplate };
};
