import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, Loader } from '@storybook';
import {
	ConfigDocumentState,
	IActionProps,
	IEnvelopeListDetails,
	IUploadDoc,
	RecipientLocalState,
	UploadedEnvelopeDocsState,
	newUploadFile,
	prepareTypeState,
	templateTypeState,
	useUploadMultiDoc,
} from 'views';
import { convertFileToBase64, formatBase64 } from 'utils';
import { prepareDashboardSelector } from 'views';

export const ActionComp: FC<IActionProps> = ({ doc, index }) => {
	const setEnvelopeData = useSetRecoilState(UploadedEnvelopeDocsState);
	const templateType = useRecoilValue(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const { deleteDoc, swapDoc, isLoaded } = useUploadMultiDoc();
	const [newUploadData, setNewUploadData] = useRecoilState(newUploadFile);
	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);
	const { isEditable } = useRecoilValue(prepareDashboardSelector);
	const localRecipients = useRecoilValue(RecipientLocalState);
	const setConfigDocumentState = useSetRecoilState(ConfigDocumentState);

	useEffect(() => {
		if (envelopeData) {
			const newUploadIds = envelopeData?.data
				.filter((item) => item.newUpload === true)
				.map((item) => item.id);
			if (newUploadIds.length > 0)
				setNewUploadData((prevUpload: string[]) => {
					return [...prevUpload, ...newUploadIds];
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelopeData]);

	const isSwapData = useMemo(() => {
		return newUploadData?.filter((item: string) => item === doc?.id).length > 0
			? false
			: true;
	}, [newUploadData, doc.id]);

	/**
	 * This will check and disable the delete document button from already uploaded docs if any user have completed the document signing
	 * */
	const isDisabledAction = useMemo(() => {
		const { newUpload } = doc;
		const isAllRecipientsPending = localRecipients.every(
			({ status }) => status === 'pending'
		);
		if ((!newUpload && isEditable && !isAllRecipientsPending) || !isLoaded) {
			return true;
		}
		return false;
	}, [doc, isEditable, isLoaded, localRecipients]);

	const handleDeleteFile = useCallback(
		async () => {
			setEnvelopeData((prev) => {
				const clonedState = JSON.parse(JSON.stringify(prev));
				const { data } = clonedState;
				const updatedDocList = data.filter(
					({ id }: IEnvelopeListDetails) => id !== doc.id
				);
				return { ...clonedState, data: updatedDocList };
			});
			setNewUploadData(newUploadData.filter((item:string) => item!==doc.id))

			// Update state by filtering out the item with a matching ID from the data array
			setConfigDocumentState((pre) => ({
				...pre,
				data: pre?.data?.filter(
					(item: { _id: string }) => item?._id !== doc.id
				) ?? [],
			}));
			
			/**
			* This condition was added because template was getting deleted when we edit and deleting all the docs for templates
			 */
			if(prepareType === 'envelope')
				deleteDoc(doc.id);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[deleteDoc, doc]
	);

	const handleSwapDocument = useCallback(
		async (e: ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.[0];
			if (file) {
				const base64pdf = await convertFileToBase64(file);
				if (base64pdf && typeof base64pdf === 'string') {
					const filePayload: IUploadDoc = {
						data: {
							originalname: file?.name || 'Document',
							size: file?.size ?? 0,
							base64File: formatBase64(base64pdf),
							extension: file.type,
						},
					};
					swapDoc(filePayload, doc.id, index);
				}
			}
			e.target.value = '';
		},
		[doc.id, index, swapDoc]
	);

	const isSwapVisible =
		templateType === 'overlay' &&
		prepareType === 'envelope' &&
		!doc.newUpload &&
		isSwapData;
	return (
		<div
			className={`upload-doc-table__${
				!isSwapVisible ? 'action-wrapper-swap' : 'action-wrapper'
			}`}
		>
			{isSwapVisible && (
				<div className="upload-doc-table__swap-doc-wrapper">
					{isLoaded ? (
						<>
							<label
								htmlFor={`swap-${doc.id}`}
								className="upload-doc-table__swap-doc"
							>
								Swap Doc.
							</label>
							<input
								accept=".pdf, .doc, .docx"
								type="file"
								id={`swap-${doc.id}`}
								onChange={handleSwapDocument}
							/>
						</>
					) : (
						<Loader className="loader-blue" dimension={12} />
					)}
				</div>
			)}

			<Button
				handleClick={handleDeleteFile}
				disabled={isDisabledAction}
				label={
					<>
						<i className="ri-delete-bin-5-fill" />
					</>
				}
				className="esign-button__ghost--danger esign-button__small upload-doc-table__delete-button"
			/>
		</div>
	);
};
