import { FC, useMemo } from 'react'

import { Button, Modal } from '@storybook'
import { ADD_RECIPIENT_WANRING_STRING, TEMPLATE_NAME_WANRING_STRING } from 'views/editor-dashboard/store'
import { ASSETS } from 'constant';

interface IWarningModal {
    showModal: boolean,
    onClose: () => void,
    onProceed: () => void,
    modalType: string,
}

export const WarningModal: FC<IWarningModal> = ({
    onClose,
    onProceed,
    showModal,
    modalType = ''
}) => {
    const {NO_RECIPIENT} = ASSETS;

    const warningBody = useMemo(() => {
        return !modalType ? TEMPLATE_NAME_WANRING_STRING.LABEL : 
        (<div className='no-recipient-container'>
            <img src={NO_RECIPIENT} alt="No Recipient" />
            <h4>{ADD_RECIPIENT_WANRING_STRING.HEADER}</h4>
            <p>{ADD_RECIPIENT_WANRING_STRING.LABEL}</p>
        </div>)
    }, [NO_RECIPIENT, modalType])

    const primaryBtnlabel = useMemo(() => {
        return !modalType ? "Proceed" : 'Add Recipient'
    }, [modalType])

    return(
        <Modal
            isOpen={showModal}
            modalName="Warning Modal"
            closeModal={onClose}
            className="warning-modal"
            showCloseBtn={true}
            isStopOutsideClick={false}
        >
            {!modalType && <div className="warning-modal__header">Note</div>}
			<div className="warning-modal__body">

                <p>{warningBody}</p>
            </div>
        
            {/* Render a close button */}
			<div className="warning-modal__footer">
				<Button
					className="esign-button__filled esign-button__filled--secondary esign-button__large"
					handleClick={onClose}
					label="Close"
				/>
                <Button
					className="esign-button__filled esign-button__filled--primary esign-button__large"
					handleClick={onProceed}
					label={primaryBtnlabel}
				/>
			</div>
			
        </Modal>

    )
}