import type { IPurpose } from "states";
import type { ITemplateSource } from "types";
import type { IFieldNodeType, IRecipientType } from "views";

type IMessageType =
  | "FILE_LIMIT_EXCEEDS_MESSAGE"
  | "RECIPIENT_SIGN_MESSAGE"
  | "EMAIL_UNIQUE_MESSAGE"
  | "MAX_RECIPIENT_LIMIT_MESSAGE"
  | "FILES_SUPPORTED_MESSAGE"
  | "DOCUMENT_UPLOAD_FAIL_MESSAGE"
  | "DOCUMENT_UPLOAD_MESSAGE"
  | "OPTIONAL_FIELD_MESSAGE"
  | "URL_LINK_MESSAGE"
  | "SIGNATURE_UPLOAD_MESSAGE"
  | "API_ERROR_MESSAGE"
  | "SIGNATURE_NOT_UPLOAD_MESSAGE"
  | "TEMPLATE_SAVED_MESSAGE"
  | "ONE_FILE_REQUIRED"
  | "UPLOAD_FAILED"
  | "FILE_UPLOADED"
  |"DOCUMENT_UPLOAD_SUCESS_MESSAGE"
  |"INITIAL_EMPTY_ERROR_MESSAGE"
  |"INITIAL_MAX_LENGTH_ERROR_MESSAGE"
  |"ONE_NEED_TO_SIGN_REQUIRED";

export const MESSAGE: { [key in Uppercase<IMessageType>]: string } = {
  FILE_LIMIT_EXCEEDS_MESSAGE: "File size exceeds the maximum limit.",
  FILES_SUPPORTED_MESSAGE: "Only pdf, doc and docx files are supported.",
  SIGNATURE_UPLOAD_MESSAGE: "Signature or initial can not be more than 1 MB.",
  API_ERROR_MESSAGE: "Something went wrong, please try again later!",
  SIGNATURE_NOT_UPLOAD_MESSAGE: "Failed to remove Signature/Initial",
  URL_LINK_MESSAGE: "Provide the valid link in the url",
  OPTIONAL_FIELD_MESSAGE: "Please add at least one non-optional field",
  DOCUMENT_UPLOAD_MESSAGE: "Please upload atleast one document",
  DOCUMENT_UPLOAD_FAIL_MESSAGE: "Failed to upload the document",
  MAX_RECIPIENT_LIMIT_MESSAGE: "Maximum 25 recipients is allowed",
  EMAIL_UNIQUE_MESSAGE: "Emails should be unique",
  RECIPIENT_SIGN_MESSAGE:
    'There should be at least one recipient as type "Needs to sign"',

  // SUCESS-MESSAGE
  TEMPLATE_SAVED_MESSAGE: "Template saved successfully.",
  DOCUMENT_UPLOAD_SUCESS_MESSAGE: "Document uploaded successfully",
  ONE_FILE_REQUIRED: "Please upload at least one document",
  FILE_UPLOADED: "Document uploaded successfully",
  UPLOAD_FAILED:"Failed to upload the document",
  ONE_NEED_TO_SIGN_REQUIRED: 'There should be at least one active recipient has type "Needs to sign"',
  INITIAL_EMPTY_ERROR_MESSAGE: "Initials can't be empty",
  INITIAL_MAX_LENGTH_ERROR_MESSAGE: "Max length can be 3"
};



export const ENVELOPE_PURPOSE: { [key in Uppercase<IPurpose>]: IPurpose } = {
	ACCREDITATION: 'accreditation',
	MULTISIGNAGREEMENT: 'multisignAgreement',
	SELFSIGN: 'selfsign',
	SIGNAGREEMENT: 'signAgreement',
	WORKFLOW: 'workflow',
};

export const RECIPIENT_TYPE : {[key in Uppercase< IRecipientType>]: IRecipientType } = {
  DEFAULT: "default",
  ONBOARDING: "onboarding",
}

export const CONFIGURE_COMPLETE_MESSAGE = {
  PACKET_SAVED : "Packet saved successfully",
  INVIATATION_SENT :"Invitation sent",
}

export type IRecipientStatus = "pending" | "completed" | "underReview" | "approved"

export const RECIPIENT_STATUS: {
	[key in Uppercase<IRecipientStatus>]: IRecipientStatus;
} = {
	APPROVED: 'approved',
	COMPLETED: 'completed',
	PENDING: 'pending',
	UNDERREVIEW: 'underReview',
};

export const BUTTON_LABEL = {
	PENDING: 'pending',
	SEND_FOR_REVIEW: 'Send for Review',
	FINISH: 'Finish',
	NEXT: 'Next',
	START: 'Start',
	SAVE_AND_CLOSE: 'Save and Close',
};

export const VIEW_SIGN_PROD_DATE = new Date("13/Mar/24 11:52");

export const TEMPLATE_SOURCE: {
	[key in Uppercase<ITemplateSource>]: Lowercase<key>;
} = {
	DEFAULT: 'default',
	ONBOARDING: 'onboarding',
};


export type ITabSource = "common" | "standard" | "compliance" | "kyb" | "custom" | "questionnaire" | "questionaire"

export const TAB_SOURCE: { [key in Uppercase<ITabSource>]: Lowercase<key> } = {
  COMMON: "common",
  COMPLIANCE: "compliance",
  CUSTOM: "custom",
  KYB: "kyb",
  QUESTIONNAIRE: "questionnaire",
  QUESTIONAIRE: "questionaire",
  STANDARD: "standard"	
};

export const FIELD_TYPE: {
	[key in Uppercase<IFieldNodeType>]: Lowercase<key>;
} = {
	CHECKBOX: 'checkbox',
	RADIOGROUP: 'radiogroup',
	DATE: 'date',
	EMAIL: 'email',
	HYPERLINK: 'hyperlink',
	INITIAL: 'initial',
	NAME: 'name',
	SIGNATURE: 'signature',
	TEXT: 'text',
	TITLE: 'title',
};