export const APPEARANCE_OPTIONS = [
    { id: 'only-checkbox', label: 'Only checkbox' },
    { id: 'checkbox-with-value', label: 'Checkbox with value' },
    { id: 'only-option-value', label: 'Only option value' },
];

export const APPEARANCE_CONFIG_KEYS = {
    onlyCheckbox: 'only-checkbox',
    checkboxWithValue: 'checkbox-with-value',
    onlyOptionVaue: 'only-option-value'
}

export const CHECKBOX_CONFIG_KEYS = {
    checkboxAppearance: 'checkboxAppearance',
    checkboxValue: 'checkboxValue'
}

export const CHECKBOX_TO_TEXT_SIZE_MAP : { [key: string]: string } = {
    "8px": "6px",
    "12px": "10px",
    "16px": "12px",
    "20px": "14px",
    "24px": "16px",
    "28px": "18px",
    "32px": "24px",
}

export const CHECKBOX_DRAG_POSITION_WITH_LABEL: { [key: string]: { [key: string]: string } } = {
    "8px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "14px",
        "only-option-value": "6px"
    },
    "12px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "24px",
        "only-option-value": "12px"
    },
    "16px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "28px",
        "only-option-value": "14px"
    },
    "20px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "33px",
        "only-option-value": "15px"
    },
    "24px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "36px",
        "only-option-value": "16px"
    },
    "28px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "40px",
        "only-option-value": "16px"
    },
    "32px": { 
        "only-checkbox": "0px",
        "checkbox-with-value": "52px",
        "only-option-value": "20px"
    },
}

