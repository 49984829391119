import { FC, useMemo } from "react";

import { TAB_SOURCE } from "constant";
import { useRecoilValue } from "recoil";
import {
  ColorPickerComponent,
  DropdownComponent,
  DropItemsState,
  IDropedItems,
  IFieldNode,
  NumberInputComponent,
  SelectedConfigNode,
  TextAreaComponent,
  TextInputComponent,
  ToggleComponent,
} from "views";

interface IProps {
  field: IFieldNode;
}

export const NodeConfigSetting: FC<IProps> = ({ field }) => {
  const {
    default: defaultValue,
    key,
    label,
    type,
    options,
    placeholder,
    textType,
    validationField
  } = field;

 
  const selectedNode = useRecoilValue(SelectedConfigNode);  
  const dropItemStates = useRecoilValue(DropItemsState);  
  
  const shouldRunDefaultSelecttion = useMemo(() => {
    const foundIndex = dropItemStates.findIndex(
      (item: IDropedItems) => item.id === selectedNode?.id
    );
    const foundItem = dropItemStates[foundIndex];
    if(foundItem && foundItem.metadata){
      if(foundItem.metadata?.validation?.type === "date" && foundItem.metadata?.validation?.value === ""){
        return true;
      }
    }
    return false;
  }, [selectedNode?.id, dropItemStates])
  
  const renderInput = useMemo(() => {
    if (type === "input") {
      if (textType === "number") {
        return (
          <NumberInputComponent
            placeholder={placeholder ?? ""}
            name={key}
            defaultValue={defaultValue}
            label={label}
          />
        );
      }
      return (
        <TextInputComponent
          label={label}
          placeholder={placeholder ?? ""}
          name={key}
          defaultValue={defaultValue}
        />
      );
    }
    return <></>;
  }, [defaultValue, key, label, placeholder, textType, type]);

  const renderControlField = useMemo((): JSX.Element => {
    switch (type) {
      case "textArea":
        return (
          <TextAreaComponent
            label={label}
            placeholder={placeholder ?? ""}
            row={2}
            name={key}
            defaultValue={defaultValue}
          />
        );
        case "toggle":
          // In common field tab toggle will not visible
          return !(selectedNode?.source === TAB_SOURCE.COMMON && key === "readOnly") ? (
            <ToggleComponent
              label={label}
              name={key}
              defaultValue={defaultValue}
            />
					) : (
						<></>
					);
      case "select":
        return (
          <DropdownComponent
            label={label}
            options={options ?? []}
            name={key}
            defaultValue={defaultValue}
            validationFields={validationField}
            selectedValidationType={selectedNode?.validationType}
            shouldDefaultSelection={shouldRunDefaultSelecttion}
          />
        );
      case "colorPicker":
        return (
          <ColorPickerComponent
            label={label}
            defaultValue={defaultValue}
            name={key}
          />
        );
      case "input":
        return renderInput;
      default:
        return renderInput;
    }
  }, [defaultValue, key, label, options, placeholder, renderInput, type, selectedNode]);

  return <>{renderControlField} </>;
};
